import NewsItem from '../../../Components/NewsItem/NewsItem'

export default function News() {
    return (
        <>
            <h1>Nouveautés</h1>
            <NewsItem header="25 Mars 2025">
                <p>
                    Une grosse mise à jour technique, mais avec également
                    quelques évolutions dans l'utilisation de la plateforme !
                </p>
                <br />
                <p className="font-bold">Vérification du statut d'un rp</p>
                <ul className="list-disc ml-8 mb-4">
                    <li>
                        Le statut de vos RPs en cours n'est plus rafraichi dès
                        que vous arrivez sur l'onglet. Pour des questions de
                        performance de l'application, le statut de vos RPs est
                        désormais conservé en cache quelques heures afin de ne
                        pas surcharger la plateforme.
                    </li>
                    <li>
                        Il est toujours possible de rafraichir manuellement le
                        statut de vos RPs en cliquant sur l'icone à droite des
                        onglets
                    </li>
                    <li>
                        Le statut de vos RPs est également rafraichi quand vous
                        écrivez un RP sur la plateforme et que vous le publiez !
                        Dans ce cas, Airpéger rafraichit automatiquement tous
                        les RPs liés au personnage avec lequel vous venez de
                        poster votre RP.
                    </li>
                </ul>
                <p className="font-bold">Sauvegarde du brouillon</p>
                <ul className="list-disc ml-8">
                    <li>
                        Ajout d'un message d'avertissement si vous essayez de
                        naviguer sur une autre page alors que vous brouillon
                        n'est pas encore totalement enregistré
                    </li>
                    <li>
                        Ajout de la possibilité d'enregistrer manuellement son
                        brouillon
                    </li>
                    <li>
                        Ajout d'un message d'erreur si Airpéger n'arrive pas à
                        récupérer votre brouillon
                    </li>
                </ul>
                <br />
                <p>
                    La mise à jour est principalement technique afin de mieux
                    gérer le nombre d'utilisateurs sur la plateforme ! Vous êtes
                    de plus en plus nombreux, alors j'ai dû faire quelques
                    optimisations :-)
                </p>
                <p>
                    J'ai essayé de faire ces optimisations de manière à ce
                    qu'elles ne dérangent pas votre expérience. J'espère avoir
                    réussi, mais n'hésitez pas à me contacter si besoin :3
                </p>
            </NewsItem>
            <NewsItem header="26 Février 2025">
                <p>
                    Une mise à jour quasiment uniquement dédiée à la page
                    Statistiques, avec plusieurs nouveautés :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Ajout d'un nouveau filtre de temps : les RPs
                        d'aujourd'hui
                    </li>
                    <li>
                        Ajout d'un filtre sur les types de personnages pour ne
                        voir que les personnages actifs, les personnages
                        archivés, ou les deux
                    </li>
                    <li>
                        Ajout d'un onglet "Graphique" afin de voir vos
                        statistiques d'une manière un peu plus lisible :3
                    </li>
                    <li>Ajout du nombre de mots par RP (en moyenne)</li>
                    <li>
                        Précision ajoutée sur la taille des avatars pour les
                        personnages (400px x 640px acceptés aussi)
                    </li>
                    <li>
                        Note ajoutée au Centre d'Aide pour préciser qu'il faut
                        bien respecter les majuscules / minuscules de son pseudo
                        en se connectant
                    </li>
                </ul>
                <br />
                <p>
                    Il s'agit d'une grosse mise à jour technique sur la page
                    "Statistiques", donc n'hésitez pas à me prévenir si vous
                    rencontrez un problème !
                </p>
                <br />
                <p>
                    <strong>À noter </strong>: les couleurs des graphiques sont
                    accordées à votre thème Airpéger. Si vous souhaitez avoir de
                    nouvelles couleurs / de nouveaux thèmes, je prends toutes
                    les suggestions !
                </p>
            </NewsItem>
            <NewsItem header="19 Février 2025">
                <p>
                    Nouvelle mise à jour avec plusieurs nouvelles
                    fonctionnalités :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Possibilité d'archiver un personnage : cela vous permet
                        de sortir un personnage de vos personnages actifs, tout
                        en conservant ses statistiques !
                    </li>
                    <li>
                        Ajout d'informations sur les personnages : le nom de
                        leur forum, leurs notes spéciales
                    </li>
                    <li>Ajout du nom du forum pour les RPs</li>
                    <li>
                        Affichage d'une indication quand la plateforme est en
                        train d'enregistrer votre brouillon, ainsi que l'heure
                        de dernière sauvegarde
                    </li>
                    <li>Ajout de 2 thèmes : pink & retro</li>
                    <li>
                        Ajout de plusieurs gifs pour la page de confirmation
                        après avoir posté un RP
                    </li>
                </ul>
                <br />
                <p>
                    L'ajout du nom du forum pour les personnages et les RPs me
                    permettra d'ajouter un filtre par forum plus tard. Je sais
                    également que la possibilité d'archiver un personnage était
                    très demandée, j'espère que ces nouvelles fonctionnalités
                    vous plairont ❤️
                </p>
            </NewsItem>
            <NewsItem header="13 Février 2025">
                <p>
                    Petite mise à jour suite aux updates d'hier, avec plusieurs
                    petits correctifs :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Sélection du design vide par défaut : désormais le
                        design "pas de design" est sélectionné par défaut
                    </li>
                    <li>
                        Sélection d'une couleur dans l'éditeur de texte : le
                        fait d'appliquer une couleur ne ferme plus
                        automatiquement la fenêtre des couleurs
                    </li>
                    <li>
                        Stats personnage : désormais les majuscules / minuscules
                        sont ignorées pour les stats d'un personnage
                    </li>
                    <li>
                        Affichage du code couleur en RP de votre personnage : le
                        texte est désormais affiché en gris foncé si le code
                        couleur est clair, pour améliorer la lisibilité
                    </li>
                    <li>Ajout d'un mode plein écran sur l'éditeur de texte</li>
                    <li>
                        Modification d'un personnage : le champ "nom du
                        personnage" est désormais grisé et ne peut pas être
                        modifié
                    </li>
                    <li>
                        Sélection d'un design : petite correction de design pour
                        rendre la sélection plus évidente et visible
                    </li>
                    <li>
                        Loader pour le statut des RPs : correctif de design sur
                        le loader quand on attend de voir si un RP est à jour ou
                        non
                    </li>
                    <li>
                        Ajout d'un style dans la barre de menu quand on
                        sélectionne une partie de texte qui a déjà un style (par
                        exemple, je sélectionne un mot en gras, l'icone "bold"
                        est affichée comme sélectionnée)
                    </li>
                </ul>
                <br />
                <p>
                    Il s'agit ici de pleins de petits correctifs suite à la mise
                    à jour d'hier, avec également l'ajout d'une nouvelle
                    fonctionnalité attendue : le mode plein écran ❤️
                </p>
                <br />
                <p>
                    N'hésitez pas à me contacter si vous avez des retours suite
                    à cette mise à jour, particulièrement sur le nouvel éditeur
                    de texte et la sélection de la couleur ! :3
                </p>
            </NewsItem>
            <NewsItem header="12 Février 2025">
                <p>Mise à jour de plusieurs fonctionnalités clés : </p>
                <ul className="list-disc ml-8">
                    <li>
                        Éditeur de texte : changement d'éditeur, afin de pouvoir
                        choisir la couleur du texte de manière plus précise, et
                        également pour préparer l'ajout d'une fonctionnalité
                        "plein écran" plus tard
                    </li>
                    <li>
                        Liste des personnages : modification de la liste des
                        personnages afin de pouvoir ajouter des informations
                        (âge, occupation, avatar, ...) et créer une fiche
                        personnage
                    </li>
                    <li>Possibilité de modifier les personnages</li>
                    <li>
                        Affichage de la fiche personnage à côté de l'éditeur de
                        texte
                    </li>
                </ul>
                <br />
                <p>
                    Il s'agit ici d'une grosse mise à jour technique qui devrait
                    permettre l'ajout d'autres fonctionnalités plus tard
                    (éditeur de texte en plein écran, lien des personnages avec
                    leur forum, ...).
                </p>
                <p>J'espère que cette mise à jour vous plaira :3</p>
            </NewsItem>
            <NewsItem header="31 Décembre 2024">
                <p>Quelques petites mises à jour pour cette fin d'année :</p>
                <ul className="list-disc ml-8">
                    <li>
                        Ajout d'une sélection / désélection de tous les
                        personnages dans les filtres de la page de suivi
                    </li>
                    <li>Ajout de 2 designs de RPs (à 2 images)</li>
                    <li>
                        Correction d'un bug sur l'affichage du graphique des RPs
                        à jour vs pas à jour
                    </li>
                </ul>
                <p>
                    Un très bon réveillon à tous, et à l'année prochaine pour de
                    nouvelles fonctionnalités sur Airpéger ❤️
                </p>
            </NewsItem>
            <NewsItem header="18 Octobre 2024">
                <p>
                    Mise à jour du suivi des RPs pour ajouter deux
                    fonctionnalités :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Pouvoir suivre le statut d'un rp manuellement : si
                        Airpéger ne parvient pas à détecter le statut de votre
                        RP de manière automatique, vous pouvez désormais choisir
                        de sélectionner son statut manuellement
                    </li>
                    <li>
                        Voir ses statistiques de RP sous forme de graphique (RPs
                        à jour vs pas à jour, proportion de RP par personnage,
                        ...)
                    </li>
                </ul>
                <p>
                    J'ai aussi profité de cette mise à jour pour ajouter un
                    design à 2 images, et pour corriger un problème d'afficher
                    du loader sur la page de suivi des RPs.
                </p>
                <p>
                    N'hésitez pas à me dire si vous souhaitez voir une nouvelle
                    fonctionnalité sur la plateforme, ou si vous avez un retour
                    ❤️
                </p>
            </NewsItem>
            <NewsItem header="13 Octobre 2024">
                <p>
                    Mise à jour de la page de suivi des RPs pour ajouter deux
                    nouvelles catégories :
                </p>
                <ul className="list-disc ml-8">
                    <li>Les RPs terminés</li>
                    <li>Les RPs à ouvrir</li>
                </ul>
                <p>
                    Vous pouvez donc désormais archiver un RP une fois qu'il est
                    terminé, et ajouter une note pour vous souvenir d'ouvrir vos
                    futurs RPs. J'espère que ces nouveaux onglets vous seront
                    utiles ❤️
                </p>
            </NewsItem>
            <NewsItem header="14 Septembre 2024">
                <p>
                    Petite mise à jour pour ranger les différents designs de RP
                    dans différentes catégories pour simplifier le choix :
                </p>
                <ul className="list-disc ml-8">
                    <li>Designs sans images</li>
                    <li>Design avec 1 image</li>
                    <li>Design avec 2 images</li>
                    <li>Design avec 3 images</li>
                </ul>
                <p>
                    Si vous souhaitez qu'un nouveau design soit ajouté,
                    n'hésitez pas à me contacter !
                </p>
            </NewsItem>
            <NewsItem header="31 Août 2024">
                <p>
                    Ajout de plusieurs nouvelles fonctionnalités sur la page
                    "RPs" :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Possibilité de noter <strong>manuellement</strong> la
                        date de dernière réponse d'un RP. Vous pouvez modifier
                        vos RPs existants pour ajouter la date !
                    </li>
                    <li>
                        Tri des RPs par date de dernière réponse, par ordre
                        alphabétique, et par forum
                    </li>
                    <li>
                        Ajout du nombre de RPs dans votre liste, avec ou sans
                        les filtres !
                    </li>
                </ul>
                <p>
                    J'aurais aimé pouvoir récupérer la date de dernière réponse
                    à un RP de manière automatique, mais ce n'était pas
                    possible. Vous pouvez continuer à utiliser la plateforme
                    sans ajouter la date de dernière réponse, ou vous servir de
                    cette nouvelle fonctionnalité pour répondre à vos RPs dans
                    l'ordre ❤️
                </p>
            </NewsItem>
            <NewsItem header="14 Août 2024">
                <p>
                    Ajout de plusieurs designs pour vos rps avec notamment
                    l'ajout de plusieurs designs pour des RPs à 3 (donc avec 3
                    images, et 2 pseudos auxquels on répond).
                </p>
                <p>
                    Si vous utilisez souvent un design et que vous voulez
                    l'ajouter à la plateforme, n'hésitez pas à{' '}
                    <a href="mailto:airpeger@gmail.com">me contacter</a>, je
                    pourrai voir pour ajouter un thème similaire à la plateforme
                    ❤️
                </p>
            </NewsItem>
            <NewsItem header="20 Mars 2024">
                <ul className="list-disc ml-8">
                    <li>Ajout d'un filtre sur les personnages liés aux RPs</li>
                    <li>
                        Ajout de deux dark themes : midnight 🌑 et forest 🌳
                    </li>
                    <li>
                        Modification du lien "supprimer le personnage" pour
                        mettre une icône
                    </li>
                    <li>
                        Correction d'un bug avec l'affichage intempestif de la
                        modale de confirmation
                    </li>
                </ul>
                <p>
                    Comme promis, après avoir ajouté la fonctionnalité qui
                    permet de lier les RPs à des personnages, le filtre par
                    personnage est désormais disponible ❤️ On m'avait également
                    suggéré un filtre ou un tri par forum, mais le filtre par
                    personnage me paraissait plus adapté !
                </p>
            </NewsItem>
            <NewsItem header="27 Janvier 2024">
                <p>Mise à jour de la page de tracking des RPs :</p>
                <ul className="list-disc ml-8">
                    <li>Ajout d'un filtre sur le statut des RPs</li>
                    <li>Ajout du nom du RP</li>
                    <li>
                        Ajout du personnage lié au RP (pour permettre plus tard
                        un filtre là-dessus également)
                    </li>
                    <li>Ajout de la possibilité de modifier un RP suivi</li>
                </ul>
                <p>
                    Il s'agit en fait d'une première grosse modification
                    technique qui devrait ensuite permettre d'ajouter d'autres
                    filtres et tris sur la page de suivi des RPs 😊
                </p>
            </NewsItem>
            <NewsItem header="28 Septembre 2023">
                <p>Très légère mise à jour pour corriger quelques détails :</p>
                <ul className="list-disc ml-8">
                    <li>
                        Modification du menu sur mobile pour rendre la
                        navigation plus simple
                    </li>
                    <li>
                        Modification de l'affichage du chargement (dans le
                        bouton plutôt qu'au-dessus)
                    </li>
                    <li>
                        Correction de l'affichage de la page de confirmation sur
                        mobile
                    </li>
                    <li>
                        Correction de la couleur de la pastille "personnage
                        inactif" dans les statistiques
                    </li>
                    <li>
                        Correction de l'affichage du message d'information sur
                        la page des statistiques
                    </li>
                </ul>
            </NewsItem>
            <NewsItem header="22 Septembre 2023">
                <p>
                    Une petite mise à jour a été publiée avec quelques
                    améliorations :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Ajout du thème <strong>orchid</strong> 💐, un nouveau
                        thème sombre
                    </li>
                    <li>
                        Correction d'un bug qui empêchait de supprimer un
                        personnage
                    </li>
                    <li>
                        Ajout de messages d'informations si le compte n'a pas de
                        personnage ou de RP à suivre
                    </li>
                    <li>
                        Création d'une page d'erreur si l'URL n'existe pas sur
                        le site
                    </li>
                    <li>
                        Nouveau design de la page de confirmation une fois un RP
                        posté
                    </li>
                    <li>Correction du design en version mobile</li>
                </ul>
            </NewsItem>
            <NewsItem header="16 Septembre 2023">
                <p>Le site a été mis à jour avec plusieurs nouveautés :</p>
                <ul className="list-disc ml-8">
                    <li>Un nouveau design</li>
                    <li>
                        Une validation des différentes étapes quand vous créez
                        un RP : si vous oubliez de renseigner une info, le site
                        vous le dira
                    </li>
                    <li>
                        Un meilleur affichage des stats : on n'affiche plus le
                        champ 'Aucun personnage' si tous vos RPs sont bien
                        attribués à un personnage
                    </li>
                    <li>
                        De nouveaux <b>thèmes</b>
                    </li>
                </ul>
                <p>
                    Cette mise à jour devrait être transparente, il s'agissait
                    surtout d'une refonte technique et j'en ai profité pour
                    apporter quelques améliorations ! 😊
                </p>
                <p className="my-4">
                    <b>À noter</b> : Suite à la mise à jour, vous devriez avoir
                    retrouvé le thème blanc / light par défaut. Vous pouvez{' '}
                    <b>remettre un autre thème (dont des thèmes dark)</b> en
                    cliquant sur votre avatar, depuis votre compte.
                </p>
                <p>
                    Comme d'habitude, si vous avez des questions / remarques,
                    n'hésitez pas à{' '}
                    <a href="mailto:airpeger@gmail.com">me contacter</a> !
                </p>
                <p>❤️</p>
            </NewsItem>
        </>
    )
}
