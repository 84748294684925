import { useCurrentEditor } from '@tiptap/react'
import WordCount from '../WordCount/WordCount'
import { useRPContent } from '../../Context/rpContent'
import { useDraftSaving } from '../../Context/draftSaving'
import { useEffect } from 'react'
import StatusSpinner from '../StatusSpinner/StatusSpinner'
import saveDraftIcon from '../../Assets/save-draft.svg'
import saveDraftDark from '../../Assets/save-draft-dark.svg'
import { useTheme } from '../../Context/theme'
import { lightThemes } from '../../Data/const'

export default function Counter({ saveDraft }) {
    const {
        setRPContent,
        characterCount,
        setCharacterCount,
        wordCount,
        setWordCount,
    } = useRPContent()
    const { isDraftBeingSaved, lastSavedDate } = useDraftSaving()
    const { editor } = useCurrentEditor()
    const { theme } = useTheme()
    const text = JSON.stringify(editor.getText())
    const HTML = JSON.stringify(editor.getHTML(), null, 2)
    const options = { hour: 'numeric', minute: 'numeric' }
    const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(
        lastSavedDate
    )
    let wordCountVar
    if (text === '""') {
        wordCountVar = 0
    } else {
        wordCountVar = text
            .split(' ')
            .filter((item) => item !== ' ' && item !== '"').length
    }
    useEffect(() => {
        setRPContent(HTML)
        setCharacterCount(
            text.split('').filter((item) => item !== ' ' && item !== '"').length
        )
        setWordCount(wordCountVar)
    }, [
        HTML,
        editor,
        setRPContent,
        characterCount,
        setCharacterCount,
        wordCount,
        setWordCount,
        text,
        wordCountVar,
    ])
    return (
        <div className="sm:flex w-full justify-between mb-4 items-center">
            <div className="flex sm:w-1/2 sm:justify-start justify-between">
                <WordCount type="characters" count={characterCount} />
                <WordCount type="words" count={wordCount} />
            </div>
            <div className="sm:w-1/2 mt-3 sm:mt-0 sm:text-right flex justify-end items-center">
                {isDraftBeingSaved && (
                    <div className="mr-4">
                        <StatusSpinner />
                    </div>
                )}
                {lastSavedDate && !isDraftBeingSaved && (
                    <p className="text-sm">
                        Brouillon sauvegardé à {formattedDate}
                    </p>
                )}
                <img
                    className="cursor-pointer save-icon w-6 h-6 ml-4"
                    onClick={saveDraft}
                    src={
                        lightThemes.includes(theme)
                            ? saveDraftIcon
                            : saveDraftDark
                    }
                    alt="Enregistrer brouillon"
                />
            </div>
        </div>
    )
}
