import { createContext, useContext, useState } from 'react'

const RPCountContext = createContext()

export function RPCountWrapper({ children }) {
    const [fullRPList, setFullRPList] = useState([])

    return (
        <RPCountContext.Provider
            value={{
                fullRPList,
                setFullRPList,
            }}>
            {children}
        </RPCountContext.Provider>
    )
}

export function useRPCount() {
    return useContext(RPCountContext)
}
