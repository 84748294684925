import { createRoute } from '@tanstack/react-router'
import { rootRoute } from './rootRoute'
import Write from '../Pages/App/Write/Write'
import Characters from '../Pages/App/Characters/Characters'
import Tracker from '../Pages/App/Tracker/Tracker'
import Stats from '../Pages/App/Stats/Stats'
import Account from '../Pages/App/Account/Account'
import Confirmation from '../Pages/App/Confirmation/Confirmation'

export const writeRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: Write,
})

export const trackerRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/rps',
    component: Tracker,
})

export const charactersRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/personnages',
    component: Characters,
})

export const statsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/statistiques',
    component: Stats,
})

export const accountRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/account',
    component: Account,
})

export const confirmationRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/confirmation',
    component: Confirmation,
})
