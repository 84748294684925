import { useState } from 'react'

import Spinner from '../../../Components/Spinner/Spinner'
import MenuItem from '../../../Components/MenuItem/MenuItem'

import Confirmation from '../../../Components/Confirmation/Confirmation'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'

import CharacterCardList from '../../../Components/CharacterCardList/CharacterCardList'

import { useCharacters } from '../../../Hooks/useCharacters'
import { useModal } from '../../../Context/modal'

export default function Characters() {
    const { isModalOpen, setIsModalOpen } = useModal()
    const [characterToUpdate, setCharacterToUpdate] = useState({})
    const [modalType, setModalType] = useState('addChar')
    const [activeTab, setActiveTab] = useState('Personnages actifs')

    const openAddModal = () => {
        setModalType('addChar')
        setIsModalOpen(true)
    }

    const openUpdateModal = (character) => {
        setModalType('updateChar')
        setIsModalOpen(true)
        setCharacterToUpdate(character)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const {
        isCharactersLoading,
        isCharactersError,
        activeCharacters,
        archivedCharacters,
    } = useCharacters()

    return (
        <>
            <div className="flex justify-between items-center">
                <h1 className="sm:ml-4">Mes Personnages</h1>
                <button
                    onClick={openAddModal}
                    className="w-52 py-2 border border-secondary bg-secondary text-secondary-text rounded-md">
                    Ajouter un personnage
                </button>
            </div>
            <section className="sm:mr-8 w-full mb-4 sm:flex sm:items-center">
                <ul className="sm:flex">
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="characters-category"
                        name="Personnages actifs">
                        Personnages actifs{' '}
                        <span className="text-xs ml-0.5">
                            ({!isCharactersLoading && activeCharacters?.length})
                        </span>
                    </MenuItem>
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="characters-category"
                        name="Personnages archivés">
                        Personnages archivés{' '}
                        <span className="text-xs ml-0.5">
                            (
                            {!isCharactersLoading && archivedCharacters?.length}
                            )
                        </span>
                    </MenuItem>
                </ul>
            </section>
            {isCharactersError && (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            )}
            {!isCharactersLoading &&
                !isCharactersError &&
                (activeTab === 'Personnages actifs' ? (
                    <CharacterCardList
                        characters={activeCharacters}
                        openUpdateModal={openUpdateModal}
                        isActiveCharacters
                    />
                ) : (
                    <CharacterCardList
                        characters={archivedCharacters}
                        openUpdateModal={openUpdateModal}
                    />
                ))}
            {isCharactersLoading && <Spinner centered />}
            {isModalOpen && (
                <Confirmation
                    closeModal={closeModal}
                    source={modalType}
                    characterToUpdate={characterToUpdate}
                />
            )}
        </>
    )
}
