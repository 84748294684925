import HelpItem from '../../../Components/HelpItem/HelpItem'
import MenuItem from '../../../Components/MenuItem/MenuItem'

import { useState } from 'react'

export default function Help() {
    const [activeTab, setActiveTab] = useState('Rédaction des RPs')
    return (
        <>
            <h1 className="sm:ml-4">Aide</h1>
            <div className="flex sm:flex-row flex-col justify-between">
                <section className="sm:mr-8 sm:w-1/4 sm:mb-0 mb-8">
                    <ul>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category"
                            name="Rédaction des RPs">
                            Rédaction des RPs
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category"
                            name="Gestion des RPs">
                            Gestion des RPs
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category"
                            name="Gestion des personnages">
                            Gestion des personnages
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category"
                            name="Statistiques">
                            Statistiques
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category"
                            name="Gestion du compte">
                            Gestion du compte
                        </MenuItem>
                    </ul>
                </section>
                <section className="sm:w-3/4">
                    {activeTab === 'Rédaction des RPs' && (
                        <>
                            <HelpItem title="Enregistrer un brouillon">
                                <p>
                                    Votre brouillon est enregistré
                                    automatiquement toutes les 30 secondes. Vous
                                    pourrez le retrouver depuis plusieurs
                                    appareils différents, à condition d'être
                                    connecté au même compte.
                                </p>
                                <p>
                                    Vous avez également la possibilité
                                    d'enregistrer <strong>manuellement</strong>{' '}
                                    votre brouillon en cliquant sur l'icone
                                    disquette en-dessous de l'éditeur de texte.
                                </p>
                                <p>
                                    Si vous essayez de quitter la page alors que
                                    votre brouillon n'est pas enregistré dans
                                    son intégralité, un message d'avertissement
                                    apparaîtra.
                                </p>
                            </HelpItem>
                            <HelpItem title="Récupérer un brouillon">
                                <p>
                                    Votre dernier brouillon est récupéré
                                    automatiquement au chargement de la page,
                                    mais cela peut prendre quelques secondes.
                                </p>
                                <p>
                                    Un message s'affiche au niveau de l'éditeur
                                    de texte pendant que le brouillon est en
                                    train d'être chargé. Il apparaît ensuite
                                    automatiquement dans l'éditeur.
                                </p>
                                <p>
                                    <strong>À noter :</strong> si la plateforme
                                    ne parvient pas à récupérer votre brouillon,
                                    un message d'erreur apparaîtra. Dans ce cas,
                                    il est conseillé de recharger la page.
                                </p>
                            </HelpItem>
                            <HelpItem title="Choisir un personnage">
                                <p>
                                    Vous pouvez choisir le personnage auquel
                                    attribuer le RP en haut de la page. Cela
                                    nous permet ensuite de comptabiliser le RP
                                    dans les statistiques de votre personnage.
                                    Si vous ne choisissez aucun personnage, les
                                    mots seront comptés dans vos statistiques,
                                    mais pas liés à un personnage spécifique.
                                </p>
                                <br />
                                <p>
                                    Le fait de choisir un personnage vous permet
                                    également de voir ses informations à côté de
                                    l'éditeur de texte :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>Son avatar</li>
                                    <li>Son nom</li>
                                    <li>Son âge</li>
                                    <li>Son occupation / métier</li>
                                    <li>
                                        Son code couleur en RP. Vous pouvez le
                                        copier en cliquant dessus pour ensuite
                                        l'appliquer directement dans l'éditeur
                                    </li>
                                    <li>
                                        Sa description, en survolant son avatar
                                    </li>
                                    <li>Ses notes spécifiques</li>
                                    <li>Son forum</li>
                                </ul>
                                <br />
                                <p>
                                    À noter : vous ne pouvez pas sélectionner un
                                    personnage archivé pour votre RP.
                                </p>
                            </HelpItem>
                            <HelpItem title="Appliquer une couleur">
                                <p>
                                    L'éditeur de texte vous permet d'appliquer
                                    une couleur à votre texte. Pour cela,
                                    sélectionnez votre texte, puis cliquez sur
                                    l'icone de couleur de texte. Une fenêtre
                                    s'ouvre avec :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>
                                        Plusieurs choix de couleurs pré-définies
                                    </li>
                                    <li>Un champ pour coller un code hex</li>
                                </ul>
                                <br />
                                <p>
                                    <strong>À noter</strong> : si vous souhaitez
                                    appliquer une couleur exacte, il faudra
                                    copier le code hex auparavant. La fenêtre de
                                    choix de couleur applique la couleur dès que
                                    le code correspond à une couleur, il peut
                                    donc appliquer la mauvaise couleur si vous
                                    tapez les caractères un par un.
                                </p>
                                <p>
                                    Par exemple : le code couleur souhaité est
                                    le #0f2c5c, vous tapez #0f2, et
                                    l'application reconnait un code couleur,
                                    elle applique donc la couleur #0f2.
                                </p>
                                <p>
                                    Pour éviter ce problème, vous pouvez copier
                                    le code couleur exact de votre personnage
                                    dans sa fiche à droite de l'éditeur de texte
                                    !
                                </p>
                            </HelpItem>
                            <HelpItem title="Choisir un design">
                                <p>
                                    La plateforme vous propose plusieurs
                                    templates pour le design de votre rp. Si
                                    votre template inclut des informations
                                    spécifiques (nom du RP, images, ...), vous
                                    pourrez les renseigner après avoir
                                    sélectionné le template. Vous pouvez
                                    également ne choisir aucun template, dans ce
                                    cas sélectionnez le design vide parmi les
                                    "designs sans images". Votre RP sera alors
                                    copié sans design spécifique.
                                </p>
                                <br />
                                <p>
                                    À noter : le design "vide" est sélectionné
                                    par défaut.
                                </p>
                            </HelpItem>
                            <HelpItem title="Copier le RP">
                                <p>
                                    Une fois que vous avez rédigé votre RP et
                                    sélectionné un design, cliquez sur 'Copier
                                    le RP'. Votre code complet sera copié
                                    automatiquement, il ne vous restera plus
                                    qu'à poster le RP sur votre forum.
                                </p>
                            </HelpItem>
                            <HelpItem title="Poster le RP">
                                <p>
                                    Une fois le RP posté, cliquez sur 'J'ai
                                    posté mon RP'. Cela permettra de prendre en
                                    compte les stats de votre RP sur le site.
                                    Attention : une fois que vous avez cliqué
                                    sur ce bouton, votre brouillon sera
                                    définitivement supprimé.
                                </p>
                            </HelpItem>
                        </>
                    )}
                    {activeTab === 'Gestion des RPs' && (
                        <>
                            <HelpItem title="Ajouter un RP">
                                <p>
                                    Vous pouvez suivre 3 types de RPs sur
                                    Airpéger :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>Vos rps en cours</li>
                                    <li>Vos rps terminés</li>
                                    <li>Vos rps à ouvrir</li>
                                </ul>
                                <br />
                                <p>
                                    <strong>Pour suivre un rp en cours</strong>,
                                    cliquez sur "Ajouter un RP", puis restez
                                    dans l'onglet "RP en cours / terminé". Vous
                                    pouvez ensuite remplir les informations de
                                    votre RP.{' '}
                                    <strong>Pour suivre un RP terminé</strong>,
                                    les étapes sont les mêmes, mais il faut
                                    penser à cocher la case "Ce RP est terminé".{' '}
                                    <strong>Pour ajouter un RP à ouvrir</strong>
                                    , allez sur l'onglet "RP à ouvrir", puis
                                    ajoutez vos notes sur le RP à ouvrir.
                                </p>
                            </HelpItem>
                            <HelpItem title="Statut du RP">
                                <p>
                                    Pour savoir si un RP est à jour ou non, nous
                                    regardons si la dernière personne qui a
                                    posté dans le RP fait partie de votre liste
                                    de personnages. Si ce n'est pas le cas, nous
                                    considérons que le RP n'est pas à jour.
                                </p>
                                <p>
                                    Le statut d'un RP n'est pas rafraichi à
                                    chaque fois que vous arrivez sur la page, il
                                    est conservé en cache quelques heures pour
                                    des questions de performance. Vous pouvez
                                    toutefois rafraichir manuellement le statut
                                    de vos RPs en cliquant sur l'icone
                                    rafraichir à droites des onglets de RPs.
                                </p>
                            </HelpItem>
                            <HelpItem title="Statut incorrect">
                                <p>
                                    Le statut qui s'affiche peut être incorrect
                                    pour plusieurs raisons différentes :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>
                                        le statut n'est pas à jour sur airpéger
                                        : dans ce cas, essayez de rafraichir le
                                        statut de vos RPs en cliquant sur
                                        l'icone rafraichir à droites des onglets
                                        de RPs.
                                    </li>
                                    <li>
                                        vous n'avez pas ajouté votre personnage
                                        sur le site, ou vous l'avez ajouté avec
                                        une orthographe différente de celle
                                        utilisée pour le forum
                                    </li>
                                    <li>
                                        le forum en question est privé, et il
                                        faut être connecté pour voir les RPs.
                                        Dans ce cas, le site ne peut pas
                                        vérifier qui a posté en dernier
                                    </li>
                                    <li>
                                        le code du forum en question a été
                                        customisé. Dans ce cas, il arrive
                                        qu'Airpéger n'arrive pas à récupérer le
                                        nom du dernier posteur. Si c'est le cas
                                        pour l'un de vos forums, n'hésitez pas à{' '}
                                        <a href="mailto:airpeger@gmail.com">
                                            me contacter
                                        </a>{' '}
                                        pour que nous puissions corriger ça.
                                    </li>
                                </ul>
                                <br />
                                <p>
                                    Si Airpéger ne parvient pas à récupérer le
                                    bon statut pour votre rp, vous pouvez
                                    également choisir de le suivre manuellement.
                                    Pour cela, modifiez votre RP et cochez la
                                    case "Je vais suivre ce RP manuellement".
                                    Dans ce cas, Airpéger n'essaiera pas de
                                    récupérer le statut de ce RP
                                    automatiquement, mais vous pourrez vous-même
                                    modifier son statut.
                                </p>
                            </HelpItem>
                            <HelpItem title="Filtrer / Trier vos RPs">
                                <p>
                                    Afin de vous faciliter la vie, Airpéger vous
                                    permet de filtrer vos RPs par :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>Statut</li>
                                    <li>Personnage</li>
                                </ul>
                                <br />
                                Vous pouvez également trier vos RPs par :
                                <ul className="list-disc ml-8">
                                    <li>
                                        Date de dernière réponse. Airpéger ne
                                        peut pas récupérer la date de dernière
                                        réponse automatiquement, mais vous
                                        pouvez la renseigner manuellement en
                                        créant et en modifiant votre RP
                                    </li>
                                    <li>Forum</li>
                                    <li>Ordre alphabétique</li>
                                </ul>
                                <br />
                                <p>
                                    À noter : par défaut, votre liste s'affiche
                                    dans l'ordre d'ajout de vos RPs.
                                </p>
                            </HelpItem>
                        </>
                    )}
                    {activeTab === 'Gestion des personnages' && (
                        <>
                            <HelpItem title="Ajouter un personnage">
                                <p>
                                    Vous pouvez ajouter un personnage depuis
                                    l'onglet "Personnages", en renseignant :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>son nom</li>
                                    <li>son âge</li>
                                    <li>sa description</li>
                                    <li>son code couleur en RP</li>
                                    <li>son occupation</li>
                                    <li>
                                        son avatar, en ajoutant le lien de
                                        l'image et en utilisant de préférence
                                        une taille de 200x320 px ou 400x640 px
                                    </li>
                                    <li>son forum</li>
                                </ul>
                                <br />
                                <p>
                                    Le fait d'ajouter un personnage vous permet
                                    de :
                                </p>
                                <ul className="list-disc ml-8">
                                    <li>lui attribuer des RPs</li>
                                    <li>
                                        voir ses informations de personnage
                                        quand vous écrivez votre RP
                                    </li>
                                    <li>
                                        suivre vos RPs à jour automatiquement
                                    </li>
                                    <li>voir des stats pour ce personnage</li>
                                </ul>
                                <br />
                                <p>
                                    À noter : Il est important d'utiliser le
                                    même nom de personnage sur Airpéger et sur
                                    votre forum, sinon le suivi du statut de vos
                                    RPs ne pourra pas se faire correctement.
                                </p>
                            </HelpItem>
                            <HelpItem title="Modifier un personnage">
                                <p>
                                    Vous pouvez modifier les informations de
                                    votre personnage en cliquant sur l'icone
                                    "modifier" à côté du nom de votre
                                    personnage. Attention toutefois, il n'est
                                    pas possible de modifier le nom de votre
                                    personnage car ses statistiques sont liées à
                                    son nom.
                                </p>
                                <p>
                                    Si besoin, vous pouvez également supprimer
                                    votre personnage de la plateforme.
                                </p>
                            </HelpItem>
                        </>
                    )}
                    {activeTab === 'Statistiques' && (
                        <>
                            <HelpItem title="Comprendre les statistiques">
                                <p>
                                    Chaque RP est lié à un personnage, la page
                                    statistiques vous permet de voir le nombre
                                    de RPs et de mots pour chacun de vos
                                    personnages. Pour que les stats d'un RP
                                    soient ajoutés, il faut obligatoirement
                                    cliquer sur 'J'ai posté mon RP' après avoir
                                    publié votre RP.
                                </p>
                            </HelpItem>
                            <HelpItem title='Stats "Aucun personnage"'>
                                <p>
                                    Si vous validez un RP sans avoir choisi de
                                    personnage lié, ces stats seront
                                    comptabilisées dans l'encart 'Aucun
                                    personnage'. Vous ne pouvez pour le moment
                                    pas attribuer un RP à un personnage une fois
                                    le RP publié, mais n'hésitez pas à{' '}
                                    <a href="mailto:airpeger@gmail.com">
                                        me contacter
                                    </a>{' '}
                                    pour que je puisse attribuer votre RP à un
                                    personnage. Dans ce cas, pensez à indiquer :
                                </p>
                                <ul className="list-disc ml-8 mt-4">
                                    <li>La date du RP</li>
                                    <li>Le nom du personnage à attribuer</li>
                                    <li>Le pseudo de votre compte Airpéger</li>
                                </ul>
                            </HelpItem>
                        </>
                    )}
                    {activeTab === 'Gestion du compte' && (
                        <>
                            <HelpItem title="Modifier son compte">
                                <p>
                                    Vous pouvez modifier votre mot de passe et
                                    votre avatar depuis la page 'Mon compte'. Il
                                    n'est pas possible de modifier votre pseudo
                                    ou l'adresse email liés à votre compte.
                                </p>
                            </HelpItem>
                            <HelpItem title="Changer le thème Airpéger">
                                <p>
                                    Airpéger propose plusieurs thèmes
                                    différents, avec notamment plusieurs thèmes
                                    sombres. Vous pouvez modifier le thème de la
                                    plateforme depuis votre compte, cliquez
                                    ensuite sur "Modifier mon compte" pour
                                    appliquer votre choix.
                                </p>
                                <br />
                                <p>
                                    Si vous avez une idée de nouveau thème,
                                    n'hésitez pas à{' '}
                                    <a href="mailto:airpeger@gmail.com">
                                        me contacter
                                    </a>{' '}
                                    pour que nous puissions l'ajouter !
                                </p>
                            </HelpItem>
                            <HelpItem title="Mot de passe oublié">
                                <p>
                                    Si vous avez oublié votre mot de passe, vous
                                    pouvez demander à le modifier depuis la page
                                    de connexion. Cliquez sur 'Mot de passe
                                    oublié', puis renseignez l'email de votre
                                    compte. Vous recevrez un email vous
                                    permettant de modifier le mot de passe de
                                    votre compte.
                                </p>
                                <br />
                                <p>
                                    <strong>Attention</strong> également à bien
                                    respecter les majuscules & minuscules de
                                    votre pseudo ET de votre mot de passe.
                                </p>
                            </HelpItem>
                        </>
                    )}
                </section>
            </div>
        </>
    )
}
