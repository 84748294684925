import { AuthWrapper } from './Context/auth'
import { ThemeWrapper } from './Context/theme'
import { RPContentWrapper } from './Context/rpContent'
import { FullscreenWrapper } from './Context/fullscreen'
import { DraftSavingWrapper } from './Context/draftSaving'
import { ModalWrapper } from './Context/modal'
import { RPCountWrapper } from './Context/rpCount'

import {
    QueryClient,
    QueryClientProvider,
    QueryCache,
} from '@tanstack/react-query'

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: async (error) => {
            if (error.message === '401') {
                window.location.href = '/accueil'
            }
        },
    }),
})

function App({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthWrapper>
                <RPCountWrapper>
                    <ModalWrapper>
                        <ThemeWrapper>
                            <DraftSavingWrapper>
                                <FullscreenWrapper>
                                    <RPContentWrapper>
                                        {children}
                                    </RPContentWrapper>
                                </FullscreenWrapper>
                            </DraftSavingWrapper>
                        </ThemeWrapper>
                    </ModalWrapper>
                </RPCountWrapper>
            </AuthWrapper>
        </QueryClientProvider>
    )
}

export default App
