import StatusSpinner from '../StatusSpinner/StatusSpinner'
import EditLight from '../../Assets/edit-light.svg'
import EditDark from '../../Assets/edit-dark.svg'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'
import Manual from '../../Assets/manual-light.svg'
import ManualDark from '../../Assets/manual-dark.svg'
import Auto from '../../Assets/auto-light.svg'
import AutoDark from '../../Assets/auto-dark.svg'
import classNames from 'classnames'

import { useCheckRP } from '../../Hooks/useCheckRP'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

import './RPItem.css'

export default function RPItem({
    rp,
    characters,
    removeRP,
    statusFilters,
    characterFilters,
    openUpdateModal,
    hasCharacterFilter,
    hasStatusFilter,
}) {
    const { theme } = useTheme()

    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }
    const { RPstatus, isRPStatusLoading } = useCheckRP(rp, characters)

    const showRP = () => {
        if (
            (hasCharacterFilter &&
                characterFilters.length === characters.length) ||
            !hasCharacterFilter
        ) {
            if (hasStatusFilter) {
                if (RPstatus) {
                    return statusFilters.includes('À jour')
                } else {
                    return statusFilters.includes('Pas à jour')
                }
            } else {
                return true
            }
        } else if (
            hasCharacterFilter &&
            characterFilters.length !== characters.length
        ) {
            if (hasStatusFilter) {
                if (RPstatus) {
                    return (
                        statusFilters.includes('À jour') &&
                        characterFilters.includes(rp.character)
                    )
                } else {
                    return (
                        statusFilters.includes('Pas à jour') &&
                        characterFilters.includes(rp.character)
                    )
                }
            } else {
                return characterFilters.includes(rp.character)
            }
        }
    }

    const deleteRP = () => {
        removeRP(rp.link)
    }

    const openModal = () => {
        openUpdateModal(rp)
    }

    return (
        (showRP() || isRPStatusLoading) && (
            <div
                className={classNames(
                    'bg-background text-main flex flex-col sm:flex-row sm:justify-between items-start sm:items-center rounded-md px-6 py-4 my-4'
                )}>
                <div className="flex flex-col sm:flex-row items-start sm:items-center w-4/5">
                    <div className="flex">
                        <div
                            className={`h-8 w-8 mr-4 p-1 bg-main-background flex items-center justify-center rounded-full`}
                            alt="test">
                            <img
                                src={
                                    rp.manualTracking
                                        ? lightThemes.includes(theme)
                                            ? ManualDark
                                            : Manual
                                        : lightThemes.includes(theme)
                                        ? AutoDark
                                        : Auto
                                }
                                className="h-4 w-4"
                                alt="tracking type"
                                title={
                                    rp.manualTracking
                                        ? 'RP suivi manuellement'
                                        : 'RP suivi automatiquement'
                                }
                            />
                        </div>
                        {!isRPStatusLoading ? (
                            <span
                                className={`w-80px text-sm text-center ${
                                    RPstatus
                                        ? 'text-green border-green bg-light-green'
                                        : 'text-red border-red bg-light-red '
                                } border border-solid py-1 rounded-md`}>
                                {RPstatus ? 'À jour' : 'Pas à jour'}
                            </span>
                        ) : (
                            <span className="w-80px h-32px text-sm text-center text-grey border border-border-color border-solid py-1 rounded-md">
                                <StatusSpinner />
                            </span>
                        )}
                    </div>
                    <p
                        className={classNames(
                            `sm:mx-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color truncate w-32`
                        )}>
                        {rp.character || 'Aucun personnage'}
                    </p>
                    {rp?.forum && (
                        <p
                            className={classNames(
                                `sm:mr-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded bg-main-background truncate w-32`
                            )}>
                            {rp.forum}
                        </p>
                    )}
                    <a
                        href={rp.link}
                        target="_blank"
                        rel="noreferrer"
                        className="sm:my-0 my-4 rp-item-link truncate text-sm">
                        {rp.name || rp.link}
                    </a>
                    <p className="text-xs sm:mx-4 sm:mb-0 mb-4">
                        {rp.lastDate &&
                            new Date(rp.lastDate).toLocaleDateString(
                                'fr-FR',
                                dateOptions
                            )}
                    </p>
                </div>
                <div className="flex">
                    <img
                        className="cursor-pointer edit-icon w-6 h-6 mr-4"
                        onClick={openModal}
                        src={lightThemes.includes(theme) ? EditLight : EditDark}
                        alt="modifier"
                    />
                    <img
                        className="cursor-pointer trash-icon w-6 h-6"
                        onClick={deleteRP}
                        src={lightThemes.includes(theme) ? Trash : TrashDark}
                        alt="supprimer"
                    />
                </div>
            </div>
        )
    )
}
