import { createRootRoute } from '@tanstack/react-router'
import Header from '../Components/Navigation/Header/Header'
import Footer from '../Components/Navigation/Footer/Footer'
import { Outlet } from '@tanstack/react-router'
import NotFound from '../Pages/App/NotFound/NotFound'

export const rootRoute = createRootRoute({
    component: () => (
        <div className="relative">
            <Header />
            <main className="bg-main-background text-main w-full max-w-full sm:px-8 px-4 pt-8 pb-16">
                <Outlet />
            </main>
            <Footer />
        </div>
    ),
    notFoundComponent: NotFound,
})
