import React from 'react'
import { useState, useEffect } from 'react'

import RPItem from '../RPItem/RPItem'
import InfoMessage from '../InfoMessage/InfoMessage'
import Filter from '../Filter/Filter'
import Tooltip from '../Tooltip/Tooltip'
import classNames from 'classnames'
import { lightThemes } from '../../Data/const'

import { useRPCount } from '../../Context/rpCount'

import arrowDown from '../../Assets/Arrows/down.svg'
import arrownDownDark from '../../Assets/Arrows/down-dark.svg'

export default function RPOngoing({
    removeRP,
    allRPs,
    characters,
    openUpdateModal,
    theme,
    setFilteredCount,
    statusFilters,
    setStatusFilters,
    characterFilters,
    setCharacterFilters,
}) {
    const [RPList, setRPList] = useState(allRPs)
    const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false)
    const [isCharacterFilterOpen, setIsCharacterFilterOpen] = useState(false)
    const [isSortingOpen, setIsSortingOpen] = useState(false)
    const [isMassSelected, setIsMassSelected] = useState(true)
    const [sorting, setSorting] = useState('')

    const { fullRPList } = useRPCount()

    useEffect(() => {
        setRPList(allRPs)
    }, [allRPs])

    useEffect(() => {
        let countWithFilters
        if (
            (characterFilters &&
                characterFilters.length === characters.length) ||
            !characterFilters
        ) {
            if (statusFilters) {
                countWithFilters = fullRPList.filter((item) =>
                    statusFilters.includes(item.status)
                ).length
            } else {
                countWithFilters = fullRPList.length
            }
        } else if (
            characterFilters &&
            characterFilters.length !== characters.length
        ) {
            if (statusFilters) {
                countWithFilters = fullRPList.filter(
                    (item) =>
                        statusFilters.includes(item.status) &&
                        [...characterFilters].includes(item.character)
                ).length
            } else {
                countWithFilters = fullRPList.filter((item) =>
                    [...characterFilters].includes(item.character)
                ).length
            }
        }
        setFilteredCount(countWithFilters)
    }, [
        statusFilters,
        characterFilters,
        fullRPList,
        setFilteredCount,
        characters.length,
    ])

    useEffect(() => {
        let newList
        if (sorting === 'forum') {
            newList = Array.from(allRPs).sort((a, b) =>
                a.link.localeCompare(b.link)
            )
            setRPList(newList)
        } else if (sorting === 'date') {
            newList = Array.from(allRPs).sort((a, b) =>
                !b.lastDate || b.lastDate === ''
                    ? -1
                    : new Date(a.lastDate) - new Date(b.lastDate)
            )
            setRPList(newList)
        } else if (sorting === 'alphabetical') {
            newList = Array.from(allRPs).sort((a, b) =>
                a.name.localeCompare(b.name)
            )
            setRPList(newList)
        } else {
            setRPList(allRPs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const openStatusFilter = () => {
        setIsStatusFilterOpen(!isStatusFilterOpen)
    }

    const openCharacterFilter = () => {
        setIsCharacterFilterOpen(!isCharacterFilterOpen)
    }

    const openSorting = () => {
        setIsSortingOpen(!isSortingOpen)
    }

    const handleSorting = (event) => {
        setSorting(event.target.value)
    }

    const handleMassSelection = () => {
        if (isMassSelected) {
            setCharacterFilters([])
        } else {
            setCharacterFilters(characters)
        }
        setIsMassSelected(!isMassSelected)
    }

    return (
        <>
            <div className="flex sm:flex-row flex-col">
                <div
                    className="status-filter-block"
                    data-testid="status-filter">
                    <div
                        className="px-4 py-2 w-48 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                        data-testid="status-filter-open"
                        onClick={openStatusFilter}>
                        <p
                            className={
                                statusFilters.length !== 2
                                    ? 'filter-group-active'
                                    : 'filter-group-inactive'
                            }>
                            {statusFilters.length === 2
                                ? 'Tous les statuts'
                                : statusFilters.length === 0
                                ? 'Aucun statut'
                                : statusFilters[0]}
                        </p>
                        <img
                            src={
                                lightThemes.includes(theme)
                                    ? arrowDown
                                    : arrownDownDark
                            }
                            alt="flèche"
                            className={classNames(
                                isStatusFilterOpen ? 'rotate-180' : null,
                                'w-4 h-4 ease-in-out duration-300'
                            )}
                        />
                    </div>
                    {isStatusFilterOpen && (
                        <div className="p-4 w-48 mt-2 filter-group rounded-md border border-solid border-border-color">
                            <Filter
                                filters={statusFilters}
                                setFilters={setStatusFilters}
                                value="À jour"
                            />
                            <Filter
                                filters={statusFilters}
                                setFilters={setStatusFilters}
                                value="Pas à jour"
                            />
                        </div>
                    )}
                </div>
                <div
                    className="sm:ml-3 sm:mt-0 mt-3 character-filter-block"
                    data-testid="character-filter">
                    <div
                        className="px-4 py-2 w-64 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                        data-testid="character-filter-open"
                        onClick={openCharacterFilter}>
                        <p
                            className={
                                characterFilters.length === characters.length
                                    ? 'filter-group-inactive'
                                    : 'filter-group-active'
                            }>
                            {characterFilters.length === characters.length
                                ? 'Tous les personnages'
                                : characterFilters.length === 1
                                ? characterFilters[0]
                                : characterFilters.length === 0
                                ? 'Aucun personnage'
                                : 'Personnages filtrés'}
                        </p>
                        <img
                            src={
                                lightThemes.includes(theme)
                                    ? arrowDown
                                    : arrownDownDark
                            }
                            alt="flèche"
                            className={classNames(
                                isCharacterFilterOpen ? 'rotate-180' : null,
                                'w-4 h-4 ease-in-out duration-300'
                            )}
                        />
                    </div>
                    {isCharacterFilterOpen && (
                        <div className="p-4 w-64 mt-2 filter-group rounded-md border border-solid border-border-color">
                            <div className="flex items-center my-1">
                                <input
                                    className="mr-3 w-5 h-5 status-filter"
                                    type="checkbox"
                                    value="Tous les personnages"
                                    checked={isMassSelected}
                                    onChange={handleMassSelection}
                                />
                                <label
                                    className="font-medium"
                                    htmlFor="mass-selection">
                                    Tous les personnages
                                </label>
                            </div>
                            {characters.map((character, index) => (
                                <Filter
                                    filters={characterFilters}
                                    setFilters={setCharacterFilters}
                                    value={character}
                                    key={`${character}-${index}`}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className="sm:ml-3 sm:mt-0 mt-3 character-filter-block"
                    data-testid="rp-sort">
                    <div
                        className="px-4 py-2 w-80 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                        data-testid="rp-sort-open"
                        onClick={openSorting}>
                        <p
                            className={
                                sorting === ''
                                    ? 'filter-group-inactive'
                                    : 'filter-group-active'
                            }>
                            Trier les RPs
                        </p>
                        <img
                            src={
                                lightThemes.includes(theme)
                                    ? arrowDown
                                    : arrownDownDark
                            }
                            alt="flèche"
                            className={classNames(
                                isSortingOpen ? 'rotate-180' : null,
                                'w-4 h-4 ease-in-out duration-300'
                            )}
                        />
                    </div>
                    {isSortingOpen && (
                        <div className="p-4 w-80 mt-2 filter-group rounded-md border border-solid border-border-color">
                            <div className="flex items-center my-1">
                                <input
                                    className="mr-3 w-5 h-5 status-filter"
                                    type="radio"
                                    value="alphabetical"
                                    checked={sorting === 'alphabetical'}
                                    onChange={handleSorting}
                                />
                                <label htmlFor="alphabetical">
                                    Ordre alphabétique
                                </label>
                            </div>
                            <div className="flex items-center my-1">
                                <input
                                    className="mr-3 w-5 h-5 status-filter"
                                    type="radio"
                                    value="forum"
                                    checked={sorting === 'forum'}
                                    onChange={handleSorting}
                                />
                                <label htmlFor="regular">Forum</label>
                            </div>
                            <div className="flex items-center my-1">
                                <input
                                    className="mr-3 w-5 h-5 status-filter"
                                    type="radio"
                                    value="date"
                                    checked={sorting === 'date'}
                                    onChange={handleSorting}
                                />
                                <label htmlFor="date">
                                    Date de dernière réponse
                                </label>{' '}
                                <Tooltip tooltipStyle="sm:mt-[3px] mt-0">
                                    <p>
                                        Les RPs seront triés du plus ancien au
                                        plus récent en fonction de la date de
                                        dernière réponse que vous avez indiquée.
                                        Vous pouvez modifier un RP pour mettre à
                                        jour sa date de dernière réponse.
                                    </p>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {allRPs?.length > 0 ? (
                RPList.map((item) => (
                    <RPItem
                        key={`${item.name}-${item.character}`}
                        rp={item}
                        characters={characters}
                        removeRP={removeRP}
                        statusFilters={statusFilters}
                        characterFilters={characterFilters}
                        openUpdateModal={openUpdateModal}
                        hasCharacterFilter={true}
                        hasStatusFilter={true}
                    />
                ))
            ) : (
                <InfoMessage>
                    Vous n'avez pas encore ajouté de RP à suivre. Ajoutez un RP
                    que vous souhaitez suivre via le bouton ci-dessus.
                </InfoMessage>
            )}
        </>
    )
}
