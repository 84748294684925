import CharacterItem from '../CharacterItem/CharacterItem'
import InfoMessage from '../InfoMessage/InfoMessage'

export default function CharacterCardList({
    characters,
    openUpdateModal,
    isActiveCharacters,
}) {
    return (
        <>
            <div className="flex flex-wrap justify-between">
                {characters.length > 0 ? (
                    characters.map((item, index) => (
                        <CharacterItem
                            key={`${item}-${index}`}
                            character={item}
                            openUpdateModal={openUpdateModal}
                        />
                    ))
                ) : (
                    <InfoMessage>
                        Vous n'avez pas encore ajouté de personnage{' '}
                        {isActiveCharacters ? 'actif' : 'archivé'} à votre
                        compte. Ajoutez un personnage grâce au formulaire
                        ci-dessus !{' '}
                        {!isActiveCharacters &&
                            'Vous pouvez également archiver un personnage existant en le modifiant.'}
                    </InfoMessage>
                )}
            </div>
        </>
    )
}
