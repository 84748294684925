import StatItem from '../StatItem/StatItem'

export default function StatsList({
    activeCharacters = [],
    noCharacter = {},
    archivedCharacters = [],
}) {
    const showActiveCharacters = activeCharacters?.length > 0
    const showNoCharacters = noCharacter?.noCharWords > 0
    const showArchivedCharacters = archivedCharacters?.length > 0
    return (
        <>
            {showActiveCharacters && (
                <div className="mt-6">
                    <h2>Personnages actifs</h2>
                    {activeCharacters.map((item) => {
                        return (
                            <StatItem
                                words={item.words}
                                RPs={item.RPs}
                                wordsByRP={item.wordByRP}
                                character={item.character}
                                key={item.character}
                            />
                        )
                    })}
                </div>
            )}
            {showNoCharacters && (
                <StatItem
                    words={noCharacter?.noCharWords}
                    RPs={noCharacter?.noCharRPs}
                    wordsByRP={noCharacter?.noCharWordsByRP}
                    character="Aucun personnage"
                />
            )}
            {showArchivedCharacters && (
                <>
                    <h2 className="mt-6">Personnages archivés</h2>
                    {archivedCharacters.map((item) => {
                        return (
                            <StatItem
                                words={item.words}
                                RPs={item.RPs}
                                wordsByRP={item.wordByRP}
                                character={item.character}
                                key={item.character}
                            />
                        )
                    })}
                </>
            )}
        </>
    )
}
