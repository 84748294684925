import React from 'react'
import { useState, useEffect } from 'react'
import { lightThemes } from '../../Data/const'
import Filter from '../Filter/Filter'
import InfoMessage from '../InfoMessage/InfoMessage'
import classNames from 'classnames'

import arrowDown from '../../Assets/Arrows/down.svg'
import arrownDownDark from '../../Assets/Arrows/down-dark.svg'
import RPFinishedItem from '../RPFinishedItem/RPFinishedItem'

export default function RPFinished({
    allRPs,
    characters,
    removeRP,
    openUpdateModal,
    setFinishedFilteredCount,
    theme,
    finishedCharacterFilters,
    setFinishedCharacterFilters,
}) {
    const [rpFinishedCount, setRpFinishedCount] = useState([])
    const [isCharacterFilterOpen, setIsCharacterFilterOpen] = useState(false)

    useEffect(() => {
        const countWithFilters = rpFinishedCount.filter((item) =>
            finishedCharacterFilters.includes(item.character)
        ).length
        setFinishedFilteredCount(countWithFilters)
    }, [finishedCharacterFilters, rpFinishedCount, setFinishedFilteredCount])

    const openCharacterFilter = () => {
        setIsCharacterFilterOpen(!isCharacterFilterOpen)
    }

    return (
        <>
            <div
                className="sm:mt-0 mt-3 character-filter-block"
                data-testid="character-filter">
                <div
                    className="px-4 py-2 w-56 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                    onClick={openCharacterFilter}
                    data-testid="character-filter-open">
                    <p
                        className={
                            finishedCharacterFilters.length ===
                            characters.length
                                ? 'filter-group-inactive'
                                : 'filter-group-active'
                        }>
                        {finishedCharacterFilters.length === characters.length
                            ? 'Tous les personnages'
                            : finishedCharacterFilters.length === 1
                            ? finishedCharacterFilters[0]
                            : finishedCharacterFilters.length === 0
                            ? 'Aucun personnage'
                            : 'Personnages filtrés'}
                    </p>
                    <img
                        src={
                            lightThemes.includes(theme)
                                ? arrowDown
                                : arrownDownDark
                        }
                        alt="flèche"
                        className={classNames(
                            isCharacterFilterOpen ? 'rotate-180' : null,
                            'w-4 h-4 ease-in-out duration-300'
                        )}
                    />
                </div>
                {isCharacterFilterOpen && (
                    <div className="p-4 w-56 mt-2 filter-group rounded-md border border-solid border-border-color">
                        {characters.map((character, index) => (
                            <Filter
                                filters={finishedCharacterFilters}
                                setFilters={setFinishedCharacterFilters}
                                value={character}
                                key={`${character}-${index}`}
                            />
                        ))}
                    </div>
                )}
            </div>
            {allRPs?.length > 0 ? (
                <div className="sm:flex sm:flex-wrap sm:justify-between">
                    {allRPs.map((item) => (
                        <RPFinishedItem
                            key={`${item.name}-${item.character}`}
                            rp={item}
                            characters={characters}
                            removeRP={removeRP}
                            openUpdateModal={openUpdateModal}
                            setRpFinishedCount={setRpFinishedCount}
                            hasCharacterFilter={true}
                            characterFilters={finishedCharacterFilters}
                        />
                    ))}
                </div>
            ) : (
                <InfoMessage>
                    Vous n'avez pas encore ajouté de RP terminé. Ajoutez un RP
                    terminé via le bouton ci-dessus.
                </InfoMessage>
            )}
        </>
    )
}
