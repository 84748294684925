import { Chart } from 'react-google-charts'
import { useTheme } from '../../Context/theme'
import { lightThemes } from '../../Data/const'

export default function StatsGraph({
    wordGraphList,
    RPGraphList,
    wordByRPGraphList,
}) {
    const { theme } = useTheme()
    const options = {
        chartArea: { width: '95%', height: '80%' },
        titlePosition: 'none',
        fontName: 'Ubuntu',
        backgroundColor: 'transparent',
        hAxis: {
            textStyle: {
                color: lightThemes.includes(theme) ? '#333' : '#dadada',
            },
            gridlines: {
                color: 'transparent',
            },
        },
        vAxis: {
            textPosition: 'none',
            gridlines: {
                color: 'transparent',
            },
        },
        legend: 'none',
    }

    return (
        <>
            <div className="flex sm:flex-row sm:flex-wrap sm:justify-between flex-col">
                <div className="sm:w-[49%]">
                    <h4 className="text-center text-sm mb-1 bg-background rounded-md sm:px-8 px-4 py-3 mt-8">
                        Nombre de mots
                    </h4>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="450px"
                        data={wordGraphList}
                        options={options}
                    />
                </div>
                <div className="sm:w-[49%]">
                    <h4 className="text-center text-sm mb-1 bg-background rounded-md sm:px-8 px-4 py-3 mt-8">
                        Nombre de RPs
                    </h4>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="450px"
                        data={RPGraphList}
                        options={options}
                    />
                </div>
                <div className="sm:w-[49%]">
                    <h4 className="text-center text-sm mb-1 w-full bg-background rounded-md sm:px-8 px-4 py-3 mt-8">
                        Nombre de mots par RP
                    </h4>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="450px"
                        data={wordByRPGraphList}
                        options={options}
                    />
                </div>
            </div>
        </>
    )
}
