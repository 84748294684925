import React from 'react'
import { useState, useEffect } from 'react'

import GraphLight from '../../../Assets/graph-light.svg'
import GraphDark from '../../../Assets/graph-dark.svg'
import RefreshLight from '../../../Assets/refresh.svg'
import RefreshDark from '../../../Assets/refresh-dark.svg'

import Confirmation from '../../../Components/Confirmation/Confirmation'
import { deleteRP, deleteToOpenRP } from '../../../utils/utils'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import MenuItem from '../../../Components/MenuItem/MenuItem'
import Spinner from '../../../Components/Spinner/Spinner'

import { useTheme } from '../../../Context/theme'
import { lightThemes } from '../../../Data/const'

import RPOngoing from '../../../Components/RPOngoing/RPOngoing'
import RPFinished from '../../../Components/RPFinished/RPFinished'
import RPToOpen from '../../../Components/RPToOpen/RPToOpen'

import { useCharacters } from '../../../Hooks/useCharacters'
import { useRPs } from '../../../Hooks/useRPs'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { countRPPerStatus } from '../../../utils/utils'

import { useModal } from '../../../Context/modal'
import { useRPCount } from '../../../Context/rpCount'

export default function Tracker() {
    const {
        characterNames = [],
        isCharactersLoading,
        isCharactersError,
    } = useCharacters()
    const { isModalOpen, setIsModalOpen } = useModal()
    const { fullRPList, setFullRPList } = useRPCount()

    const [filteredCount, setFilteredCount] = useState()
    const [upToDateTotal, setUpToDateTotal] = useState(0)
    const [notUpToDateTotal, setNotUpToDateTotal] = useState(0)
    const [finishedFilteredCount, setFinishedFilteredCount] = useState()
    const [rpToUpdate, setRpToUpdate] = useState('')
    const [modalType, setModalType] = useState('add')
    const [activeTab, setActiveTab] = useState('RPs en cours')
    const [statusFilters, setStatusFilters] = useState(['À jour', 'Pas à jour'])
    const [characterFilters, setCharacterFilters] = useState(characterNames)
    const [finishedCharacterFilters, setFinishedCharacterFilters] =
        useState(characterNames)
    const {
        characterData,
        forumData,
        allToOpenRPs,
        allOpenRPs,
        allClosedRPs,
        isRPsLoading,
        isRPsError,
    } = useRPs()

    const { theme } = useTheme()
    const hasError = isRPsError || isCharactersError
    const queryClient = useQueryClient()

    useEffect(() => {
        if (fullRPList.length > 0) {
            const { upToDate, notUpToDate } = countRPPerStatus(fullRPList)
            setUpToDateTotal(upToDate)
            setNotUpToDateTotal(notUpToDate)
        }
    }, [fullRPList])

    useEffect(() => {
        if (allOpenRPs?.length > 0) {
            const allOpenLinks = allOpenRPs?.map((item) => item.link)
            setFullRPList((fullRPList) =>
                fullRPList?.filter((item) => allOpenLinks.includes(item.link))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allOpenRPs?.length])

    const openAddModal = () => {
        setModalType('add')
        setIsModalOpen(true)
    }

    const openUpdateModal = (rpLink) => {
        setModalType('update')
        setIsModalOpen(true)
        setRpToUpdate(rpLink)
    }

    const openStatsModal = () => {
        setModalType('stats')
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const refreshStatus = () => {
        queryClient.invalidateQueries({ queryKey: ['check-rp'] })
    }

    const { mutate: removeRP } = useMutation({
        mutationKey: ['delete-rp'],
        mutationFn: (rpLink) => deleteRP(rpLink),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-rps'] })
        },
    })

    const { mutate: removeToOpenRP } = useMutation({
        mutationKey: ['delete-rp-to-open'],
        mutationFn: (rpName) => deleteToOpenRP(rpName),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-rps'] })
        },
    })

    const typeData = [
        ['Type', 'Count'],
        ['RPs en cours', allOpenRPs?.length],
        ['RPs terminés', allClosedRPs?.length],
        ['RPs à ouvrir', allToOpenRPs?.length],
    ]

    const statusData = [
        ['Status', 'Count'],
        ['RPs à jour', upToDateTotal],
        ['RPs pas à jour', notUpToDateTotal],
        [
            'En chargement',
            allOpenRPs?.length - (upToDateTotal + notUpToDateTotal),
        ],
    ]

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <h1 className="sm:ml-4">Mes RPs</h1>
                <button
                    onClick={openAddModal}
                    className="w-40 py-2 border border-secondary bg-secondary text-secondary-text rounded-md">
                    Ajouter un RP
                </button>
            </div>
            <section className="sm:mr-8 w-full mb-4 sm:flex sm:items-center">
                <ul className="sm:flex">
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs en cours">
                        RPs en cours{' '}
                        <span className="text-xs ml-0.5">
                            (
                            {activeTab === 'RPs en cours'
                                ? filteredCount
                                : allOpenRPs?.length}
                            )
                        </span>
                    </MenuItem>
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs terminés">
                        RPs terminés{' '}
                        <span className="text-xs ml-0.5">
                            (
                            {activeTab === 'RPs terminés'
                                ? finishedFilteredCount
                                : allClosedRPs?.length}
                            )
                        </span>
                    </MenuItem>
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs à ouvrir">
                        RPs à ouvrir{' '}
                        <span className="text-xs ml-0.5">
                            ({allToOpenRPs?.length})
                        </span>
                    </MenuItem>
                </ul>
                {!isRPsLoading && allOpenRPs?.length > 0 && (
                    <img
                        onClick={openStatsModal}
                        className="w-6 h-6 ml-4 mt-1 cursor-pointer graph-icon"
                        src={
                            lightThemes.includes(theme) ? GraphLight : GraphDark
                        }
                        alt="statistiques"
                    />
                )}
                {!isRPsLoading &&
                    allOpenRPs?.length > 0 &&
                    activeTab === 'RPs en cours' && (
                        <img
                            onClick={refreshStatus}
                            className="w-6 h-6 ml-4 mt-1 cursor-pointer refresh-icon"
                            src={
                                lightThemes.includes(theme)
                                    ? RefreshLight
                                    : RefreshDark
                            }
                            alt="Rafraichir le statut des RPs"
                        />
                    )}
            </section>
            {hasError && !isCharactersLoading && !isRPsLoading && (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            )}
            {(isRPsLoading || isCharactersLoading) && (
                <Spinner centered margin />
            )}
            {!hasError && !isRPsLoading && !isCharactersLoading ? (
                activeTab === 'RPs en cours' ? (
                    <RPOngoing
                        removeRP={removeRP}
                        allRPs={allOpenRPs}
                        characters={characterNames}
                        openUpdateModal={openUpdateModal}
                        theme={theme}
                        filteredCount={filteredCount}
                        setFilteredCount={setFilteredCount}
                        statusFilters={statusFilters}
                        setStatusFilters={setStatusFilters}
                        characterFilters={characterFilters}
                        setCharacterFilters={setCharacterFilters}
                    />
                ) : activeTab === 'RPs terminés' ? (
                    <RPFinished
                        removeRP={removeRP}
                        characters={characterNames}
                        allRPs={allClosedRPs}
                        theme={theme}
                        openUpdateModal={openUpdateModal}
                        setFinishedFilteredCount={setFinishedFilteredCount}
                        finishedCharacterFilters={finishedCharacterFilters}
                        setFinishedCharacterFilters={
                            setFinishedCharacterFilters
                        }
                    />
                ) : (
                    <RPToOpen allRPs={allToOpenRPs} removeRP={removeToOpenRP} />
                )
            ) : null}

            {isModalOpen && (
                <Confirmation
                    closeModal={closeModal}
                    source={modalType}
                    characters={characterNames}
                    rpToUpdate={rpToUpdate}
                    typeData={typeData}
                    statusData={statusData}
                    characterData={characterData}
                    forumData={forumData}
                />
            )}
        </>
    )
}
