export const gifs = [
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExamFtdDc3NDZhc3Y1ajdycmw4cmRwdHZ0azBubWFzYjc0bzB0ZjVxNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BPJmthQ3YRwD6QqcVD/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaHhtNWZ4bWJzYTdteXY2MGZ0cGFqNjJoNjk3YjlwdWJrZmYwY3gyNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6fJ1BM7R2EBRDnxK/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmY5dTd1eXp1ODgwd3kzaGdhc3pjaWpob3RtM2t0dmhiZzhrb3VtOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6fIUZTTDl0IDjbZS/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExb2l2Y3phNHp5djRucW16bnNzaTFibWV0cWRoZDB6YXh2MHMyZzIwaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/H1NIKdfygAAMruqArl/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdjE2MTZyMHc3czJ3M3FyaWtubmthNTBwZnE5b3JuNm4xaXJmMWhjcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/fdyZ3qI0GVZC0/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnNjNWIzaGN4ODZoOTF6bWVvYnViY3N2enN4ODFsbGJ0bm95N2l0cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/wZjlCH43M3M0U/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNDdzZ3FhcjN4d2c1eHo0amZwZnhjbzJ2Z3QyeGRoNjRxMGc0NTg3MiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/wue4QtxncWuE8/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmduYTNoZTFid3dtbWtmaTlpaDBrbWx6OHgxOXE5cWZpdWRucHNzeiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/zSzYBsqf6hUkkOM206/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzdwdGxpMzlidjgxdGh1dHV3ejRtOXRuajJranlxN3hhYnB6OXJzYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/znodP9eurvThe/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeDE3MDU5OTliNnBpM2tuNGJpenNlaGIxazVpNmNxNjl3ODM3Z210ZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3MibWRcT0AOYG7HUeT/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGE3ZGwyNzQwcDEybXp2b3BlZmNleWQxY2d4Y2R0OXdydTNsbHBjciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/2be3wzOOEh7rGTmGLo/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOHU5dGFqNm93YXVoN3hocnMwc2tnMWM5cjM0ZHh5NnVycHRmMHRnNyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/hv14mGOF3MY7wDKPkE/giphy-downsized-large.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdXI2cXRqcmRyODI0MzVrNWY1cDI2OXFua2R2ZjFsdWp5dHV3ZnczdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/S6qkS0ETvel6EZat45/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOWk4eW9sNzRub3B4emJ4bzV3OWZqM3Eyd3hyeXNyZDc0NjByd2hreiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oz8xAFtqoOUUrsh7W/giphy.gif',
    'https://media.giphy.com/media/cOvgh3VjLmeg8LLBtk/giphy-downsized-large.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZDd2ejI0eG45bGRwN3VhcTVzd3V5aHdnamY3YjJvbHFyaTR1MGh6NiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ruFFE06RFWOF66nk9V/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTBmMHoxZTBpeWpobXc3NWlhZW11N2w0ZjZxb2pwZXJmZjU4MXFkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gg1oxesicLSWk/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbW5zNWdzemRnanlnaDVraGlla3NtMHJxeGJncWpvZWxvMXRubTJyZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ZXBCQjBvOJ5bQXP95h/giphy.gif',
    'https://media.giphy.com/media/nbvFVPiEiJH6JOGIok/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNmZtamdnYmx6OGw2Y3R6cjFnYTNwODJhaTFmc3c4dmsxNG8xYThoayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/bfUCBgkj73arw2LUNe/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExODIwcDZ5c3d0d28xN2p5aXFyZDY3cDNsdng2aGFkZnJtcnhnb2tsciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/vxvv8DehmuFAayV2OH/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbGZ4M205NGoycnE2M2dmYnI0ZDdoMDJ6ZnZyOTBzeGVqOWNpdXMzayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oEjI5VtIhHvK37WYo/giphy.gif',
    'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExdWdiZnBsMW51NnMwY2V6cWFpdGJpeHNsdHJoNmE4ZjRlaDRxbXB6NyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Ov09jGgEThFKpxZ9eC/giphy.gif',
    'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTIxMTBkYmRydmExd2gxcGJ5dXZnMjF5a25wNjBtbGk5ZjdqM2Y5NCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/jJQC2puVZpTMO4vUs0/giphy.gif',
    'https://media.giphy.com/media/3GBG7xejOXhH7MyQkn/giphy.gif?cid=ecf05e47y62owvm9zzpkcjdcufkuh6zzzfh403qo41ur8ci1&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/XreQmk7ETCak0/giphy.gif?cid=ecf05e47y62owvm9zzpkcjdcufkuh6zzzfh403qo41ur8ci1&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/aLdiZJmmx4OVW/giphy.gif?cid=ecf05e47ylrq7jyzed8kmis5ithp6n2j2xg3ves6x969c8u6&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/xT77XWum9yH7zNkFW0/giphy.gif?cid=ecf05e47buq841jo6zxnr7px5clcd9glzxocre0svkjfgnup&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/DKnMqdm9i980E/giphy.gif?cid=ecf05e47mjkfm2ettlqunehroj5sxy7sfst8sf8g2egrelnl&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/l2JI9I2xDKW5To2uA/giphy.gif?cid=ecf05e47x0a4j5ub9flmhtfgek9gugha33lno0bh3x9bw4nr&ep=v1_gifs_search&rid=giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaHNxdjB6YThzMThhcGd2aXM1dmVlN2ptbjhsaTF6NHZuYWh5dmk2YyZlcD12MV9naWZzX3NlYXJjaCZjdD1n/ZdUnQS4AXEl1AERdil/giphy.gif',
    'https://media.giphy.com/media/etGPvG1Pqj9ks/giphy.gif?cid=ecf05e47d5wpibu9hb19jeyi9b3f8yi7gbaa2hdia0jq3zcc&ep=v1_gifs_search&rid=giphy.gif',
]
