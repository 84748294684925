import { createRoute } from '@tanstack/react-router'
import { rootRoute } from './rootRoute'
import Login from '../Pages/Auth/Login/Login'
import Signup from '../Pages/Auth/Signup/Signup'
import ResetPassword from '../Pages/Auth/ResetPassword/ResetPassword'
import PasswordRequest from '../Pages/Auth/PasswordRequest/PasswordRequest'

export const loginRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/login',
    component: Login,
})

export const signupRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/signup',
    component: Signup,
})

export const resetPasswordRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/resetpassword',
    component: ResetPassword,
})

export const forgottenPasswordRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/forgottenpassword',
    component: PasswordRequest,
})
