import { useQuery } from '@tanstack/react-query'
import { getCharacters } from '../utils/utils'

export const useCharacters = () => {
    const {
        isLoading,
        isFetching,
        isError: isCharactersError,
        data,
    } = useQuery(['get-characters'], getCharacters, {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    })
    const characters = data?.data
    const activeCharacters = characters?.filter((char) => !char?.isArchived)
    const archivedCharacters = characters?.filter((char) => char?.isArchived)
    const characterNames = characters?.map((item) => item.name)
    const isCharactersLoading = isLoading || isFetching
    return {
        characters,
        characterNames,
        activeCharacters,
        archivedCharacters,
        isCharactersLoading,
        isCharactersError,
    }
}
