import { useState } from 'react'
import { Link } from '@tanstack/react-router'

import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import ButtonSpinner from '../../../Components/ButtonSpinner/ButtonSpinner'

import { requestPassword } from '../../../utils/utils'

export default function PasswordRequest() {
    const [email, setEmail] = useState('')
    const [showError, setShowError] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const askNewPassword = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setShowError(false)
        try {
            await requestPassword(email)
            setIsLoading(false)
            setShowConfirmation(true)
            setEmail('')
        } catch (_) {
            setShowError(true)
            setShowConfirmation(false)
            setIsLoading(false)
            setEmail('')
        }
    }

    return (
        <section className="flex flex-col m-auto sm:w-1/2">
            <h1>Mot de passe oublié</h1>
            {showError ? (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue. Si le problème persiste,
                        n'hésitez pas à{' '}
                        <strong>
                            <a href="mailto:airpeger@gmail.com">me contacter</a>
                        </strong>
                        .
                    </p>
                </InfoMessage>
            ) : null}
            {showConfirmation ? (
                <InfoMessage type="info">
                    <p>Un email a été envoyé à l'adresse de votre compte.</p>
                </InfoMessage>
            ) : null}
            <form className="flex flex-col mt-4" onSubmit={askNewPassword}>
                <label htmlFor="pseudo" className="my-2">
                    Adresse email
                </label>
                <input
                    type="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    value={email}
                />
                <PrimaryButton centered clickFunc={askNewPassword}>
                    {isLoading ? (
                        <ButtonSpinner />
                    ) : (
                        'Demander un nouveau mot de passe'
                    )}
                </PrimaryButton>
            </form>
            <Link className="text-secondary mt-8 mx-auto" to="/login">
                Mot de passe retrouvé ? Se connecter
            </Link>
        </section>
    )
}
