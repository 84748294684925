import React from 'react'
import { useState, useEffect } from 'react'

import { useAuth } from '../../../Context/auth'
import { useTheme } from '../../../Context/theme'
import { useNavigate } from '@tanstack/react-router'

import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import { updateUser } from '../../../utils/utils'

import ThemeSelector from '../../../Components/ThemeSelector/ThemeSelector'
import Spinner from '../../../Components/Spinner/Spinner'
import ButtonSpinner from '../../../Components/ButtonSpinner/ButtonSpinner'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'

export default function Account() {
    const { user, userIsLoading, updatePicture, signout } = useAuth()
    const { updateTheme, theme } = useTheme()
    const navigate = useNavigate()

    const [password, setPassword] = useState('')
    const [picture, setPicture] = useState('')
    const [formLoading, setFormLoading] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [chosenTheme, setChosenTheme] = useState()

    useEffect(() => {
        if (!userIsLoading && user) {
            setIsLoading(false)
        }
        if (!userIsLoading && !user) {
            navigate({
                to: '/accueil',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userIsLoading])

    const triggerError = () => {
        setHasError(true)
    }

    const editUser = async (e) => {
        e.preventDefault()
        setHasError(false)
        setFormLoading(true)
        chosenTheme && updateTheme(chosenTheme)
        try {
            const { data } = await updateUser(password, picture)
            setShowConfirmation(true)
            if (data.message.startsWith('Avatar')) {
                updatePicture(picture)
            }
        } catch (_) {
            triggerError()
        } finally {
            setPassword('')
            setPicture('')
            setFormLoading(false)
        }
    }

    const logout = () => {
        localStorage.removeItem('airpeger-token')
        signout()
        navigate({
            to: '/accueil',
        })
    }
    return (
        <section className="flex flex-col m-auto sm:w-1/2">
            <div className="flex items-center justify-between">
                <h1>Mon Compte</h1>
                <p
                    className="text-secondary mt-2 mx-auto text-right mr-0 cursor-pointer"
                    onClick={logout}>
                    Se déconnecter
                </p>
            </div>
            {hasError ? (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            ) : null}
            {showConfirmation ? (
                <InfoMessage type="info">
                    <p>Vos modifications ont bien été prises en compte.</p>
                </InfoMessage>
            ) : (
                ''
            )}
            {!isLoading ? (
                <form
                    action=""
                    method="post"
                    className="flex flex-col mt-4"
                    onSubmit={editUser}>
                    <label htmlFor="email" className="my-2">
                        Adresse email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={user.email}
                        disabled
                        className="bg-disabled px-4 py-2 mb-4 rounded-md border border-solid border-border-color"
                    />
                    <label htmlFor="pseudo" className="my-2">
                        Pseudo
                    </label>
                    <input
                        type="text"
                        id="pseudo"
                        value={user.pseudo}
                        disabled
                        className="bg-disabled px-4 py-2 mb-4 rounded-md border border-solid border-border-color"
                    />
                    <label htmlFor="password" className="my-2">
                        Modifier mon mot de passe
                    </label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Nouveau mot de passe"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    />
                    <label htmlFor="avatar" className="mb-2">
                        Modifier mon avatar
                    </label>
                    <input
                        type="text"
                        id="avatar"
                        placeholder="Lien de mon nouvel avatar"
                        onChange={(e) => setPicture(e.target.value)}
                        value={picture}
                        className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    />
                    <label htmlFor="avatar" className="mb-2">
                        Modifier mon thème
                    </label>
                    <div className="flex flex-wrap">
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="light"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="dark"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="mint"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="fall"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="halloween"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="orchid"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="midnight"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="forest"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="retro"
                        />
                        <ThemeSelector
                            theme={theme}
                            selected={chosenTheme}
                            setChosenTheme={setChosenTheme}
                            value="pink"
                        />
                    </div>
                    <PrimaryButton centered clickFunc={editUser}>
                        {formLoading ? (
                            <ButtonSpinner />
                        ) : (
                            'Modifier mon compte'
                        )}
                    </PrimaryButton>
                </form>
            ) : (
                <Spinner centered margin />
            )}
        </section>
    )
}
