import { useQuery } from '@tanstack/react-query'
import { getStatsv2 } from '../utils/utils'

export const useStats = () => {
    const {
        isLoading,
        isFetching,
        isError: isStatsError,
        data,
    } = useQuery(['get-stats'], getStatsv2, {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    })
    const stats = data?.data
    const activeCharacters = stats?.filter(
        (rp) => !rp.isArchived && rp.character !== 'Aucun personnage'
    )
    const archivedCharacters = stats?.filter(
        (rp) => rp.isArchived && rp.character !== 'Aucun personnage'
    )
    const noCharacter = stats?.filter(
        (rp) => rp.character === 'Aucun personnage'
    )[0]
    const isStatsLoading = isLoading || isFetching
    return {
        activeCharacters,
        archivedCharacters,
        noCharacter,
        isStatsLoading,
        isStatsError,
    }
}
