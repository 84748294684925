export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max)
}

export const removeSpaces = (text = '') => {
    const backslashRemoved = text.replaceAll('\\"', '"')
    return backslashRemoved.substring(1, backslashRemoved.length - 1)
}

export const countRPPerStatus = (countByStatus) => {
    const upToDateCount = Array.from(countByStatus).filter(
        (item) => item.status === 'À jour'
    ).length
    const notUpToDateCount = Array.from(countByStatus).filter(
        (item) => item.status === 'Pas à jour'
    ).length
    return {
        upToDate: upToDateCount,
        notUpToDate: notUpToDateCount,
    }
}

export const countRPPerCharacter = (allRPs) => {
    const formattedRPs = Array.from(allRPs).map((item) =>
        item.character === '' ? 'Aucun personnage' : item.character
    )
    const uniqueCharacter = [...new Set(formattedRPs)]
    const countPerCharacter = uniqueCharacter.map((character) => [
        character,
        formattedRPs.filter((char) => char === character)?.length,
    ])
    return [['Personnages', 'Count'], ...countPerCharacter]
}

export const countRPPerForum = (allRPs) => {
    const formattedRPs = Array.from(allRPs).map(
        (item) => item.link.split('//')[1] || null
    )
    const reformattedRPs = Array.from(formattedRPs).map(
        (item) => item?.split('.')[0] || 'Aucun forum'
    )
    const allForums = Array.from(reformattedRPs).map((item) =>
        !item ? 'Aucun forum' : item
    )
    const uniqueForum = [...new Set(allForums)]
    const countPerForum = uniqueForum.map((forum) => [
        forum,
        reformattedRPs.filter((fofo) => fofo === forum)?.length,
    ])
    return [['Forums', 'Count'], ...countPerForum]
}

export const mapAllTimeStats = ({
    activeCharacters,
    noCharacter,
    archivedCharacters,
    colors,
    characterFilter,
}) => {
    const showActiveCharacters = characterFilter.includes('Personnages actifs')
    const showArchivedCharacters = characterFilter.includes(
        'Personnages archivés'
    )

    const noCharWords = noCharacter?.allTimeWordCount
    const noCharRPs = noCharacter?.allTimeRPCount
    const noCharWordsByRP = Math.round(noCharWords / noCharRPs) || 0
    const activeListStats = showActiveCharacters
        ? activeCharacters?.map((item) => ({
              character: item.character,
              words: item.allTimeWordCount,
              RPs: item.allTimeRPCount,
              wordByRP:
                  Math.round(item.allTimeWordCount / item.allTimeRPCount) || 0,
          }))
        : []
    const archivedListStats = showArchivedCharacters
        ? archivedCharacters?.map((item) => ({
              character: item.character,
              words: item.allTimeWordCount,
              RPs: item.allTimeRPCount,
              wordByRP:
                  Math.round(item.allTimeWordCount / item.allTimeRPCount) || 0,
          }))
        : []
    const activeGraphWordCount = showActiveCharacters
        ? activeCharacters?.map((item) => [
              item.character,
              item.allTimeWordCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordCount = showArchivedCharacters
        ? archivedCharacters?.map((item) => [
              item.character,
              item.allTimeWordCount,
              '#dadada',
          ])
        : []
    const activeGraphRPCount = showActiveCharacters
        ? activeCharacters?.map((item) => [
              item.character,
              item.allTimeRPCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphRPCount = showArchivedCharacters
        ? archivedCharacters?.map((item) => [
              item.character,
              item.allTimeRPCount,
              '#dadada',
          ])
        : []
    const activeGraphWordByRP = showActiveCharacters
        ? activeCharacters?.map((item) => [
              item.character,
              Math.round(item.allTimeWordCount / item.allTimeRPCount) || 0,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordByRP = showArchivedCharacters
        ? archivedCharacters?.map((item) => [
              item.character,
              Math.round(item.allTimeWordCount / item.allTimeRPCount) || 0,
              '#dadada',
          ])
        : []
    return {
        statsList: [activeListStats, archivedListStats],
        noCharacterList: { noCharWords, noCharRPs, noCharWordsByRP },
        wordGraphList: activeGraphWordCount &&
            archivedGraphWordCount && [
                ['Personnage', 'Nombre de mots', { role: 'style' }],
                ...activeGraphWordCount,
                ...archivedGraphWordCount,
            ],
        RPGraphList: activeGraphWordCount &&
            archivedGraphWordCount && [
                ['Personnage', 'Nombre de RPs', { role: 'style' }],
                ...activeGraphRPCount,
                ...archivedGraphRPCount,
            ],
        wordByRPGraphList: activeGraphWordCount &&
            archivedGraphWordCount && [
                ['Personnage', 'Nombre de mots par RP', { role: 'style' }],
                ...activeGraphWordByRP,
                ...archivedGraphWordByRP,
            ],
    }
}

export const mapTodayStats = ({
    activeCharacters,
    noCharacter,
    archivedCharacters,
    colors,
    characterFilter,
}) => {
    const showActiveCharacters = characterFilter.includes('Personnages actifs')
    const showArchivedCharacters = characterFilter.includes(
        'Personnages archivés'
    )

    const noCharWords = noCharacter?.todayWordCount
    const noCharRPs = noCharacter?.todayRPCount
    const noCharWordsByRP = Math.round(noCharWords / noCharRPs) || 0
    const activeListStats = showActiveCharacters
        ? activeCharacters.map((item) => ({
              character: item.character,
              words: item.todayWordCount,
              RPs: item.todayRPCount,
              wordByRP:
                  Math.round(item.todayWordCount / item.todayRPCount) || 0,
          }))
        : []
    const archivedListStats = showArchivedCharacters
        ? archivedCharacters.map((item) => ({
              character: item.character,
              words: item.todayWordCount,
              RPs: item.todayRPCount,
              wordByRP:
                  Math.round(item.todayWordCount / item.todayRPCount) || 0,
          }))
        : []
    const activeGraphWordCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.todayWordCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.todayWordCount,
              '#dadada',
          ])
        : []
    const activeGraphRPCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.todayRPCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphRPCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.todayRPCount,
              '#dadada',
          ])
        : []
    const activeGraphWordByRP = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              Math.round(item.todayWordCount / item.todayRPCount) || 0,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordByRP = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              Math.round(item.todayWordCount / item.todayRPCount) || 0,
              '#dadada',
          ])
        : []
    return {
        statsList: [activeListStats, archivedListStats],
        noCharacterList: { noCharWords, noCharRPs, noCharWordsByRP },
        wordGraphList: [
            ['Personnage', 'Nombre de mots', { role: 'style' }],
            ...activeGraphWordCount,
            ...archivedGraphWordCount,
        ],
        RPGraphList: [
            ['Personnage', 'Nombre de RPs', { role: 'style' }],
            ...activeGraphRPCount,
            ...archivedGraphRPCount,
        ],
        wordByRPGraphList: [
            ['Personnage', 'Nombre de mots par RP', { role: 'style' }],
            ...activeGraphWordByRP,
            ...archivedGraphWordByRP,
        ],
    }
}

export const mapThisMonthStats = ({
    activeCharacters,
    noCharacter,
    archivedCharacters,
    colors,
    characterFilter,
}) => {
    const showActiveCharacters = characterFilter.includes('Personnages actifs')
    const showArchivedCharacters = characterFilter.includes(
        'Personnages archivés'
    )

    const noCharWords = noCharacter?.thisMonthWordCount
    const noCharRPs = noCharacter?.thisMonthRPCount
    const noCharWordsByRP = Math.round(noCharWords / noCharRPs) || 0
    const activeListStats = showActiveCharacters
        ? activeCharacters.map((item) => ({
              character: item.character,
              words: item.thisMonthWordCount,
              RPs: item.thisMonthRPCount,
              wordByRP:
                  Math.round(item.thisMonthWordCount / item.thisMonthRPCount) ||
                  0,
          }))
        : []
    const archivedListStats = showArchivedCharacters
        ? archivedCharacters.map((item) => ({
              character: item.character,
              words: item.thisMonthWordCount,
              RPs: item.thisMonthRPCount,
              wordByRP:
                  Math.round(item.thisMonthWordCount / item.thisMonthRPCount) ||
                  0,
          }))
        : []
    const activeGraphWordCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.thisMonthWordCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.thisMonthWordCount,
              '#dadada',
          ])
        : []
    const activeGraphRPCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.thisMonthRPCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphRPCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.thisMonthRPCount,
              '#dadada',
          ])
        : []
    const activeGraphWordByRP = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              Math.round(item.thisMonthWordCount / item.thisMonthRPCount) || 0,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordByRP = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              Math.round(item.thisMonthWordCount / item.thisMonthRPCount) || 0,
              '#dadada',
          ])
        : []
    return {
        statsList: [activeListStats, archivedListStats],
        noCharacterList: { noCharWords, noCharRPs, noCharWordsByRP },
        wordGraphList: [
            ['Personnage', 'Nombre de mots', { role: 'style' }],
            ...activeGraphWordCount,
            ...archivedGraphWordCount,
        ],
        RPGraphList: [
            ['Personnage', 'Nombre de RPs', { role: 'style' }],
            ...activeGraphRPCount,
            ...archivedGraphRPCount,
        ],
        wordByRPGraphList: [
            ['Personnage', 'Nombre de mots par RP', { role: 'style' }],
            ...activeGraphWordByRP,
            ...archivedGraphWordByRP,
        ],
    }
}

export const formatRP = ({
    selectedDesign,
    rpName,
    charName,
    charNameTwo,
    imageOne,
    imageTwo,
    imageThree,
}) => {
    let codeStart = ''
    let codeEnd = ''

    switch (selectedDesign) {
        case 'Basic':
            codeStart = `<center><br/><div style="margin-top: 10px; margin-bottom: 20px; border-bottom: 1px dashed #333333; width: 40%; padding-bottom: 5px;"><p style="font-size: 1.3em;">${rpName}</p><p style="margin-bottom: 10px;">@"${charName}"</p></div><div style="text-align: justify; width: 450px;">`
            codeEnd = `</div></center>`
            break
        case 'Framed':
            codeStart = `<center><div style="margin-top: 10px; border: 10px solid #D5E8F7; max-width: 500px; padding-bottom: 20px;"><img src="${imageOne}" alt="image rp" style="width: 100%; object-fit: cover;"/><p style="font-size: 1.3em; margin-bottom: 20px;">${rpName}</p><div style="text-align: justify; width: 80%;">`
            codeEnd = `</div></div></center>`
            break
        case 'Float':
            codeStart = `<center><div style="margin-top: 10px; max-width: 500px; padding-bottom: 20px;"><p style="font-size: 1.3em;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}"</p><div style="text-align: justify;"><div style="float: left; display: flex; flex-direction: column;"><img src="${imageOne}" alt="image rp" style="width: 70px; height: 70px; object-fit: cover; margin: 5px 15px 15px 0;"/><img src="${imageTwo}" alt="image rp" style="width: 70px; height: 70px; object-fit: cover; margin: 0 15px 15px 0;"/></div>`
            codeEnd = `</div></div></center>`
            break
        case 'Clean':
            codeStart = `<center><div style="display: flex; justify-content: center;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-right: 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-left: 10px; padding: 5px; border: 1px solid #DADADA;"/></div><p style="margin-top: 20px; margin-bottom: -5px;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}"</p><div style="text-align: justify; max-width: 450px;">`
            codeEnd = `</div></center>`
            break
        case 'Saturn':
            codeStart = `<center><div style="display: flex; text-align: justify; justify-content: center;"><div style="display: flex; flex-direction: column; margin-right: 15px; margin-top: 1.5rem;"><img src="${imageOne}" style="width: 60px;height: 60px;border-radius:100%;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border-radius:100%;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"></div><div style="width: 350px; padding: 5px; margin-top: 0px; padding-left: 20px; border-left: 1px solid #dbc5ab" ><p style="text-transform: uppercase;text-align: right; font-weight: bold;">${rpName}</p><p style="text-align: right; margin-top: -10px; margin-bottom: 30px;">@"${charName}"</p>`
            codeEnd = `</div></div></center>`
            break
        case 'Float left':
            codeStart = `<link href="https://airpeger.fr/themes/float.css" rel="stylesheet" type="text/css" /><center><div class="airpeger_float"> <div class="header"> <p class="title">${rpName}</p> <p class="name">@"${charName}"</p> </div> <div class="container"> <img src="${imageOne}"/>`
            codeEnd = `</div></div></center>`
            break
        case 'Float right':
            codeStart = `<link href="https://airpeger.fr/themes/float2.css" rel="stylesheet" type="text/css" /><center><div class="airpeger_float2"> <div class="header"> <p class="title">${rpName}</p> <p class="name">@"${charName}"</p> </div> <div class="container"> <img src="${imageOne}" />`
            codeEnd = `</div></div></center>`
            break
        case 'Jupiter':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet"><center><p style="margin-bottom:0;font-size:.7rem;font-style:italic">@"${charName}"</p><p style="margin-top:5px;margin-bottom:20px;font-family:'New Amsterdam',sans-serif;font-size:14px">${rpName}</p><div style="display:flex;justify-content:center"><img src="${imageOne}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-right:10px;padding:5px;border:1px solid #dadada"><img src="${imageTwo}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-left:10px;padding:5px;border:1px solid #dadada"></div><div style="text-align:justify;width:384px">`
            codeEnd = `</div></center>`
            break
        case 'Diamond':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
            <center><img src="${imageOne}" alt="image rp" style="width: 100px; height: 120px; clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%); object-fit: cover; margin-bottom: 20px;"/><p style="margin-bottom: 0px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><p style="margin-top: 5px; margin-bottom: 20px; font-family: 'New Amsterdam', sans-serif; font-size: 14px;">${rpName}</p><div style="text-align: justify; width: 384px; border-top: 3px solid #dbc5ab;">`
            codeEnd = `</div></center>`
            break
        case 'Ring':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
            <center><p style="margin-bottom: 0px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><p style="margin-top: 5px; margin-bottom: 20px; font-family: 'New Amsterdam', sans-serif; font-size: 14px; margin-bottom: 20px;">${rpName}</p><img src="${imageOne}" alt="image rp" style="width: 80px; height: 80px; border-radius: 100%; border: 1px solid #dbc5ab;object-fit: cover; margin-bottom: -45px;"/><div style="text-align: justify; border: 1px solid #dbc5ab; width: 400px; padding: 60px 20px 10px 20px;">`
            codeEnd = `</div></center>`
            break
        case 'Dublin':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet"><center><div style="width: 384px;text-align: justify;margin-bottom: 20px;"><div style="margin-bottom: 20px;margin-right: 30px; text-align: right;"><p style="font-family: 'Poppins', sans-serif; font-weight: 800;text-transform: lowercase;font-size: 1.1rem; margin-bottom: 5px;">${rpName}</p> <p style="margin: 0;font-size: 0.7rem;">@"${charName}"</p></div><div style="display:flex;justify-content:center"><img src="${imageOne}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-right:10px;padding:5px;border:1px solid #dadada"><img src="${imageTwo}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-left:10px;padding:5px;border:1px solid #dadada"></div><div style="text-align:justify;width:384px">`
            codeEnd = `</div></div></center>`
            break
        case 'Trapeze':
            codeStart = `<center><p style="margin-top: 20px; margin-bottom: -10px;font-weight: bold;text-transform:lowercase;">${rpName}</p><p style="margin-bottom: 10px;">@"${charName}" | @"${charNameTwo}"</p><div style="display: flex; justify-content: center;margin-bottom:30px;"><img src="${imageOne}" alt="image rp" style="width: 95px; height: 95px; margin-right: -20px;object-fit: cover; clip-path: polygon(50% 100%, 100% 0, 0 0);"/><img src="${imageTwo}" alt="image rp" style="width: 95px; height: 95px; margin: 0 -20px;object-fit: cover; clip-path: polygon(50% 0%, 0% 100%, 100% 100%);"/><img src="${imageThree}" alt="image rp" style="width: 95px; height: 95px; margin-left: -20px;object-fit: cover; clip-path: polygon(50% 100%, 100% 0, 0 0);"/></div><div style="text-align: justify; max-width: 450px; border: 1px solid #DADADA; padding: 5px 10px;margin-bottom:30px;">`
            codeEnd = `</div></center>`
            break
        case 'Moon':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><p style="text-transform: lowercase;font-size:1rem;font-family:'Poppins',sans-serif;font-weight: 600;margin-bottom: 5px;">${rpName}</p><p style="margin-bottom: 20px;margin-top: 0;">@"${charName}"</p><div style="text-align: justify; max-width: 436px;display:flex;margin-bottom: 30px;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; padding: 5px; border: 1px solid #DADADA;width: 50px;height: 100px;border-radius: 100px 0 0 100px;"/><div style="padding: 10px 15px; margin: 0 10px;">`
            codeEnd = `</div><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; padding: 5px; border: 1px solid #DADADA;width: 50px;height: 100px;border-radius: 0 100px 100px 0;align-self:flex-end;"/></div></center>`
            break
        case 'Conversation':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
            <center><div style="display: flex; justify-content:center;margin-top:50px;"><img src="${imageOne}" alt="image rp" style="width: 100px; height: 100px;clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%); object-fit: cover; margin-top: -25px;"/><div style="width: 380px;"><p style=" font-family: 'New Amsterdam', sans-serif; font-size: 14px;margin-bottom: 0;">${rpName}</p><p style="margin-bottom: 20px;margin-top:5px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><div style="display:flex;justify-content:center;"><div style="text-align: justify; border: 1px solid #DADADA;padding: 10px 15px;">`
            codeEnd = `</div></div></div></div></center>`
            break
        case 'Clean2':
            codeStart = `<center><div style="display: flex; justify-content: center;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-right: 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin:0 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageThree}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-left: 10px; padding: 5px; border: 1px solid #DADADA;"/></div><p style="margin-top: 20px; margin-bottom: -5px;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}" | @"${charNameTwo}"</p><div style="text-align: justify; max-width: 436px;">`
            codeEnd = `</div></center>`
            break
        case 'Chat':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: center;"><div style="width: 350px; padding: 5px; margin-top: 0px; padding-right: 20px; border-right: 1px solid #dadada;"><div style="padding: 5px 15px;border: 1px dashed #dadada;text-align:right;"><p style="text-transform: lowercase;font-family:'Poppins',sans-serif;font-weight: 600;";text-align: right;">${rpName}</p><p style="text-align: right; margin-top: -10px;">@"${charName}"</p></div>`
            codeEnd = `</div><div style="display: flex; flex-direction: column; margin-left: 15px;"><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"></div></div></center>`
            break
        case 'Battle':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
            <center><p style="margin-top: 5px; margin-bottom: 0; font-family: 'New Amsterdam', sans-serif; font-size: 14px;">${rpName}</p><p style="margin-bottom: 20px; margin-top: 0px;font-size: 0.7rem;">@"${charName}"</p><img src="${imageOne}" alt="image rp" style="width: 120px; height: 80px;object-fit: cover; margin-bottom: -45px;margin-right: -15px;clip-path: polygon(0 0, 100% 0%, 60% 100%, 0% 100%);"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 80px;object-fit: cover; margin-bottom: -45px;margin-left: -15px;clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);;"/><div style="text-align: justify; border: 1px solid #DADADA; width: 400px; padding: 60px 20px 10px 20px; margin-bottom: 30px;">`
            codeEnd = `</div></center>`
            break
        case 'Letter':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: space-between; align-items:center;width: 450px;margin-top: 20px;"><div><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; border-radius: 100%; padding: 2px; margin-right: 10px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; border-radius: 100%; padding: 2px;"></div><div style="text-align: right;text-transform: lowercase;font-family:'Poppins',sans-serif;flex:2; margin-left: 20px;"><p style="font-weight: 600;margin: 0;">${rpName}</p><p style="font-size: 0.7rem; margin:0;">@"${charName}"</p></div></div><div style="text-align: justify; margin-top: 20px;max-width: 450px; border: 1px solid #DADADA; padding: 10px 15px;margin-bottom:30px;">`
            codeEnd = `</div></center>`
            break
        case 'Dash':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="width: 400px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 190px;height: 100px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px; padding: 2px; margin-right:8px;"><img src="${imageTwo}" style="width: 190px;height: 100px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px; padding: 2px;"></div><div style="padding: 0px 15px;border: 1px dashed #dadada;text-align:justify; margin-top: 5px; text-align:center;"><p style="text-transform: lowercase;font-family:'Poppins',sans-serif;font-weight: 600;">[b]${rpName}[/b]</p><p style="margin-top: -15px;">@"${charName}"</p></div><div style="text-align: justify;">`
            codeEnd = `</div></div></center>`
            break
        case 'Lizzie':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: space-between; align-items:center;width: 400px; margin: 20px 0 30px 0;"><div><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; padding: 2px; margin-right: 10px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; padding: 2px;"></div><div style="text-align: right;text-transform: lowercase;font-family:'Poppins',sans-serif;flex:2; margin-left: 20px;"><p style="font-weight: 600;margin: 0;">${rpName}</p><p style="font-size: 0.7rem; margin:0;">@"${charName}"</p></div></div><div style="text-align: justify; margin-top: 20px;max-width: 400px;margin-bottom:30px;">`
            codeEnd = `</div></center>`
            break
        case 'Royal':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet"><center><div style="width: 450px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 190px;height: 190px;border: 1px solid #dadada;padding: 4px;object-fit:cover; margin: 5px 0px; margin-right:8px;"><img src="${imageTwo}" style="width: 190px;height: 190px;padding: 4px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px;"></div><div style="padding: 0px 15px;border: 1px solid #dadada;text-align:justify; margin-top: 5px; text-align:center;"><p style="text-transform: uppercase; font-size: 1rem;font-family:'Playfair Display',serif;font-weight: 600;letter-spacing: .1rem;">[b]${rpName}[/b]</p><p style="margin-top: -15px;">@"${charName}"</p></div><div style="text-align: justify; width: 410px;">`
            codeEnd = `</div></div></center>`
            break
        case 'Balance':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet"><center><div style="width: 400px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 195px;height: 100px;object-fit:cover; margin: 5px 0px; margin-right:8px;"><img src="${imageTwo}" style="width: 195px;height: 100px;object-fit:cover; margin: 5px 0px;"></div><div style="display: flex; justify-content: center;align-items: center;border-bottom:1px solid #dadada; text-align:justify; margin-top: 5px;"><p style="font-style: italic;font-family:'Playfair Display',sans-serif;font-size: 13px;font-weight: 600; margin:5px 0;">${rpName}</p></div><p style="margin-top: 3px;">@"${charName}"</p><div style="text-align: justify;">`
            codeEnd = `</div></div></center>`
            break
        case 'Vows':
            codeStart = `<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet"><center><div style="width: 400px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 65px;height: 65px;object-fit:cover; border-radius: 100px;border: 4px solid #fff; margin-right: -10px;"><img src="${imageTwo}" style="width: 65px;height: 65px;object-fit:cover; border-radius: 100px;border: 4px solid #fff; margin-left: -10px;"></div><div style="display: flex; justify-content: center;align-items: center; text-align:justify; margin-top: 5px;"><p style="font-family:'Playfair Display',sans-serif;font-size: 13px;font-weight: 700; margin: 10px 0 0 0;">${rpName}</p></div><p style="margin-top: 0;">feat @"${charName}"</p><div style="text-align: justify;">`
            codeEnd = `</div></div></center>`
            break
        case 'Violet':
            codeStart = `<div style="width: 550px; margin: 20px auto; color: #333; border: 1px solid #ccc; border-radius: 8px; padding: 15px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);"><div style="text-align: center; background-color: #4a4a7d; color: #fff; padding: 20px 0px 15px 0; border-radius: 8px 8px 0 0; font-size: 18px;">${rpName}</div><div style="display: flex; margin: 15px 0 20px 0; width: 100%;"><img src="${imageOne}" style="margin: 10px 0; margin: auto;width: 80px; height: 80px; border-radius: 100px; border: 3px solid #4a4a7d; object-fit: cover;"></div>`
            codeEnd = `<div style="text-align: end;">— @"${charName}"</div></div><div style="text-align: center; font-size: 10px;">@Duskraven</div>`
            break
        case 'Cursive':
            codeStart = `<div align="center" style="width: 550px; margin: auto; font-family: Arial, sans-serif; color: #222; border: 2px dotted #ccc; border-radius: 10px; padding: 20px; background-color: transparent; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"><div style="font-family: 'Dancing Script', cursive; font-size: 30px; color: #333; font-weight: bold; text-transform: uppercase; margin: 30px 0 20px 0;">${rpName}</div><div align="center" style="margin-top: 20px; display: flex; justify-content: space-around; width: 100%;"><img src="${imageOne}" alt="Image 1" style="width: 100px; height: 100px; border-radius: 100px; object-fit:cover;border: 2px solid #ccc;"><img src="${imageTwo}" alt="Image 2" style="width: 100px; height: 100px; border-radius: 100px; object-fit:cover; border: 2px solid #ccc;"></div><div style="padding: 15px; text-align: justify; line-height: 1.6; font-size: 14px;">`
            codeEnd = `</div><div align="right" style="font-size: 10px; color: #555; margin-top: 20px;">@Duskraven</div></div>`
            break
        default:
            // eslint-disable-next-line no-unused-vars
            codeStart = ''
            // eslint-disable-next-line no-unused-vars
            codeEnd = ''
    }
    return { codeStart, codeEnd }
}

export const mapThisYearStats = ({
    activeCharacters,
    noCharacter,
    archivedCharacters,
    colors,
    characterFilter,
}) => {
    const showActiveCharacters = characterFilter.includes('Personnages actifs')
    const showArchivedCharacters = characterFilter.includes(
        'Personnages archivés'
    )

    const noCharWords = noCharacter?.thisYearWordCount
    const noCharRPs = noCharacter?.thisYearRPCount
    const noCharWordsByRP = Math.round(noCharWords / noCharRPs) || 0
    const activeListStats = showActiveCharacters
        ? activeCharacters.map((item) => ({
              character: item.character,
              words: item.thisYearWordCount,
              RPs: item.thisYearRPCount,
              wordByRP:
                  Math.round(item.thisYearWordCount / item.thisYearRPCount) ||
                  0,
          }))
        : []
    const archivedListStats = showArchivedCharacters
        ? archivedCharacters.map((item) => ({
              character: item.character,
              words: item.thisYearWordCount,
              RPs: item.thisYearRPCount,
              wordByRP:
                  Math.round(item.thisYearWordCount / item.thisYearRPCount) ||
                  0,
          }))
        : []
    const activeGraphWordCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.thisYearWordCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.thisYearWordCount,
              '#dadada',
          ])
        : []
    const activeGraphRPCount = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              item.thisYearRPCount,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphRPCount = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              item.thisYearRPCount,
              '#dadada',
          ])
        : []
    const activeGraphWordByRP = showActiveCharacters
        ? activeCharacters.map((item) => [
              item.character,
              Math.round(item.thisYearWordCount / item.thisYearRPCount) || 0,
              colors[getRandomInt(colors.length)],
          ])
        : []
    const archivedGraphWordByRP = showArchivedCharacters
        ? archivedCharacters.map((item) => [
              item.character,
              Math.round(item.thisYearWordCount / item.thisYearRPCount) || 0,
              '#dadada',
          ])
        : []
    return {
        statsList: [activeListStats, archivedListStats],
        noCharacterList: { noCharWords, noCharRPs, noCharWordsByRP },
        wordGraphList: [
            ['Personnage', 'Nombre de mots', { role: 'style' }],
            ...activeGraphWordCount,
            ...archivedGraphWordCount,
        ],
        RPGraphList: [
            ['Personnage', 'Nombre de RPs', { role: 'style' }],
            ...activeGraphRPCount,
            ...archivedGraphRPCount,
        ],
        wordByRPGraphList: [
            ['Personnage', 'Nombre de mots par RP', { role: 'style' }],
            ...activeGraphWordByRP,
            ...archivedGraphWordByRP,
        ],
    }
}

export const isLightColor = (color) => {
    let r, g, b

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const rgb = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        )

        r = rgb[1]
        g = rgb[2]
        b = rgb[3]
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const hexColor = +(
            '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        )

        r = hexColor >> 16
        g = (hexColor >> 8) & 255
        b = hexColor & 255
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    // > 127.5 is 'light', <= 127.5 is 'dark'

    return hsp > 127.5
}

export const makeCall = async (url, options, auth) => {
    const res = await fetch(url, options)
    if (!res.ok) {
        if (res.status === 401) {
            if (auth) {
                throw new Error('Unauthorized')
            } else {
                throw new Error(res.status)
            }
        } else {
            throw new Error(res.status)
        }
    }
    const responseBody = await res.json()
    return { data: responseBody, headers: res.headers }
}

// TRACKER

export const getRPs = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getrps', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const checkRPs = async (rpLink, characters) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/checkrp', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ url: rpLink, characters: characters }),
    })
}

export const addRP = async (
    newRPLink,
    newRPName,
    newRPCharacter,
    newLastDate,
    isClosed,
    isManual,
    manualStatus,
    newForumName
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/trackrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: newRPLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
            isClosed: isClosed,
            isManual: isManual,
            manualStatus: manualStatus,
            forum: newForumName,
        }),
    })
}

export const addRPToOpen = async (
    newToOpenName,
    newToOpenCharacter,
    newToOpenDescription
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/rptoopen', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            name: newToOpenName,
            character: newToOpenCharacter,
            description: newToOpenDescription,
        }),
    })
}

export const updateRP = async (
    rpLink,
    newRPName,
    newRPCharacter,
    newLastDate,
    isClosed,
    isManual,
    manualStatus,
    forum
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/updaterp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: rpLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
            isClosed: isClosed,
            isManual: isManual,
            manualStatus: manualStatus,
            forum: forum,
        }),
    })
}

export const deleteRP = async (RPToDelete) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/deleterp', {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ rpLink: RPToDelete }),
    })
}

export const deleteToOpenRP = async (RPToDelete) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/deletetoopenrp', {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ rpName: RPToDelete }),
    })
}

// CHARACTERS

export const getCharacters = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/getcharacters',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
        }
    )
}

export const addCharacter = async ({
    newCharacterName,
    newCharacterAge,
    newCharacterRpColor,
    newCharacterOccupation,
    newCharacterDescription,
    newCharacterPicture,
    newCharacterNotes,
    newCharacterForum,
    newCharacterIsArchived,
}) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/addcharacter',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                name: newCharacterName,
                occupation: newCharacterOccupation,
                age: newCharacterAge,
                rpColor: newCharacterRpColor,
                description: newCharacterDescription,
                picture: newCharacterPicture,
                notes: newCharacterNotes,
                forum: newCharacterForum,
                isArchived: newCharacterIsArchived,
            }),
        }
    )
}

export const updateCharacter = async ({
    name,
    description,
    picture,
    age,
    occupation,
    rpColor,
    notes,
    forum,
    isArchived,
}) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updatecharacter',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                name,
                description,
                picture,
                age,
                occupation,
                rpColor,
                notes,
                forum,
                isArchived,
            }),
        }
    )
}

export const deleteCharacter = async (characterName) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/deletecharacter',
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ characterName: characterName }),
        }
    )
}

// AUTH

export const login = async (pseudo, password) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/login',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ pseudo: pseudo, password: password }),
        },
        true
    )
}

export const signup = async (pseudo, password, email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/signup',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                pseudo: pseudo,
                password: password,
                email: email,
            }),
        },
        true
    )
}

export const requestPassword = async (email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/requestpassword',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ email: email }),
        }
    )
}

export const editPassword = async (password, token) => {
    return await makeCall('https://airpeger-api.vercel.app/auth/editpassword', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password, token: token }),
    })
}

export const getAuthToken = () =>
    `Bearer ${localStorage.getItem('airpeger-token')}`

export const getUser = async () => {
    if (localStorage.getItem('airpeger-token')) {
        function getPayload(jwt) {
            return JSON.parse(atob(jwt.split('.')[1]))
        }
        const payload = getPayload(localStorage.getItem('airpeger-token'))
        const expiration = new Date(payload.exp * 1000)
        const now = new Date()

        if (expiration < now) {
            return false
        } else {
            return await makeCall(
                'https://airpeger-api.vercel.app/account/getuserinfo',
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: getAuthToken(),
                    },
                }
            )
        }
    } else {
        return false
    }
}

// STATS

export const getStats = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getstats', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const getStatsv2 = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getstatsv2', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const getGlobalStats = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/stats/getuserstats',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
    )
}

// ACCOUNT

export const updateUser = async (password, picture) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updateuserinfo',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ password: password, picture: picture }),
        }
    )
}

// DRAFTS

export const getDraft = async () => {
    return await makeCall('https://airpeger-api.vercel.app/account/getdraft', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const updateDraft = async (draft) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updatedraft',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ draft: draft }),
        }
    )
}

// RP

export const postRP = async (characterName, wordCount, characterCount) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/publishrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            characterName: characterName,
            wordCount: wordCount,
            characterCount: characterCount,
        }),
    })
}
