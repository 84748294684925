import { createRoute } from '@tanstack/react-router'
import { rootRoute } from './rootRoute'
import Landing from '../Pages/App/Landing/Landing'
import Help from '../Pages/App/Help/Help'
import News from '../Pages/App/News/News'

export const newsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/news',
    component: News,
})

export const helpRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/aide',
    component: Help,
})

export const landingRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/accueil',
    component: Landing,
})
