export const mainColors =
    'bg-off-white text-off-black dark:bg-off-black dark:text-off-white mint:text-earth-green mint:bg-bg-off-white'

export const disabledInputsColors =
    'bg-disabled-gray dark:bg-disabled-off-black'

export const lightThemes = ['light', 'mint', 'fall', 'retro', 'pink']
export const darkCheckmarkThemes = ['orchid', 'midnight', 'forest']

export const themeMainColor = {
    light: 'bg-light-main-background text-light-main',
    dark: 'bg-dark-main-background text-dark-main',
    mint: 'bg-mint-main-background text-mint-main',
    fall: 'bg-fall-main-background text-fall-main',
    halloween: 'bg-halloween-main-background text-halloween-main',
    orchid: 'bg-orchid-main-background text-orchid-main',
    midnight: 'bg-midnight-main-background text-midnight-main',
    forest: 'bg-forest-main-background text-forest-main',
    retro: 'bg-retro-main-background text-retro-main',
    pink: 'bg-pink-main-background text-pink-main',
}

export const themes = [
    'light',
    'dark',
    'mint',
    'fall',
    'halloween',
    'orchid',
    'midnight',
    'forest',
    'retro',
    'pink',
]

export const themeStatColors = {
    light: [
        '#4d98cf',
        '#6aa7d4',
        '#8bb8d9',
        '#bcd5e8',
        '#2b7fbd',
        '#11629e',
        '#d3e0eb',
    ],
    dark: [
        '#4d98cf',
        '#6aa7d4',
        '#8bb8d9',
        '#bcd5e8',
        '#2b7fbd',
        '#11629e',
        '#d3e0eb',
    ],
    mint: [
        '#26a44f',
        '#3bad60',
        '#67c284',
        '#9fd4b0',
        '#cce8d5',
        '#0c5724',
        '#1a7136',
    ],
    fall: [
        '#e36414',
        '#bf6c37',
        '#bf815a',
        '#d9a686',
        '#dbc2b2',
        '#4a2c18',
        '#5a3c28',
    ],
    halloween: [
        '#e36414',
        '#bf6c37',
        '#bf815a',
        '#d9a686',
        '#ab541d',
        '#4a2c18',
        '#5a3c28',
    ],
    orchid: [
        '#e7d1fc',
        '#c1a2de',
        '#9b82b3',
        '#8761ab',
        '#9583a6',
        '#bdafc9',
        '#664b80',
    ],
    midnight: [
        '#6865ab',
        '#676891',
        '#797a9c',
        '#9899b8',
        '#cacbe3',
        '#9093e8',
        '#9895cf',
    ],
    forest: [
        '#6bab65',
        '#96cf95',
        '#759974',
        '#9db09d',
        '#c5d4c5',
        '#407a3b',
        '#50664f',
    ],
    retro: [
        '#d8dbcc',
        '#d1dbcc',
        '#ccdbd4',
        '#ccdbdb',
        '#ccd6db',
        '#ccd0db',
        '#dbd2cc',
    ],
    pink: [
        '#c0547c',
        '#cf7093',
        '#d683a1',
        '#e0a4ba',
        '#e6c8d3',
        '#c29bb8',
        '#c579b2',
    ],
}

export const START = 'Depuis le début'
export const THIS_MONTH = 'Ce mois-ci'
export const THIS_YEAR = 'Cette année'
export const TODAY = "Aujourd'hui"
