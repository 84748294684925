import classNames from 'classnames'

import Tooltip from '../Tooltip/Tooltip'

export default function StatItem({ character, words, RPs, wordsByRP }) {
    const inactive = words === 0
    const noChar = character === 'Aucun personnage'
    return (
        <div className="bg-background text-main sm:flex justify-between items-center rounded-md sm:px-8 px-4 py-4 mb-4 w-full">
            <div className="flex items-center w-2/3">
                <div
                    className={classNames(
                        inactive && !noChar
                            ? 'bg-light-gray'
                            : !inactive && !noChar
                            ? 'bg-green'
                            : !inactive && noChar
                            ? 'bg-orange'
                            : '',
                        'w-2.5 h-2.5 rounded-full sm:mr-8 mr-4'
                    )}
                    data-testid="stat-status"></div>
                <div className="flex justify-start items-center">
                    {character}
                </div>
                {noChar ? (
                    <Tooltip>
                        <p>
                            Si vous avez validé un RP sans sélectionner de
                            personnage, il apparaît dans cet encart "Aucun
                            personnage". Si vous souhaitez attribuer ce RP au
                            bon personnage, n'hésitez pas à me contacter.
                        </p>
                    </Tooltip>
                ) : null}
            </div>
            <div className="flex justify-start items-center">{words} mots</div>
            <div className="flex justify-start items-center">{RPs} RPs</div>
            <div className="flex justify-start items-center">
                {wordsByRP} mots / RP
            </div>
        </div>
    )
}
