import { useQuery } from '@tanstack/react-query'
import { checkRPs } from '../utils/utils'
import { useRPCount } from '../Context/rpCount'
import { useEffect } from 'react'

import { SIX_HOURS } from '../constants'

const manualTrackingMapping = {
    'Pas à jour': false,
    'À jour': true,
}

export const useCheckRP = (rp, characters) => {
    const { fullRPList, setFullRPList } = useRPCount()
    const { link, manualTracking, manualTrackingStatus, character } = rp
    const {
        isLoading,
        isFetching,
        isError: isStatusError,
        data,
    } = useQuery(
        ['check-rp', character, link],
        () => checkRPs(link, characters),
        {
            staleTime: SIX_HOURS,
            cacheTime: SIX_HOURS,
            enabled: !manualTracking,
            retry: false,
        }
    )
    const RPstatus = manualTracking
        ? manualTrackingMapping[manualTrackingStatus]
        : data?.data?.message
    const isRPStatusLoading = manualTracking ? false : isLoading || isFetching
    useEffect(() => {
        if (!isRPStatusLoading) {
            if (!fullRPList.find((item) => item.link === rp.link)) {
                setFullRPList((fullRPList) => [
                    ...fullRPList,
                    {
                        name: rp.name,
                        link: rp.link,
                        status: isStatusError
                            ? 'Pas à jour'
                            : RPstatus === true
                            ? 'À jour'
                            : 'Pas à jour',
                        character: rp.character,
                        lastDate: rp.lastDate,
                    },
                ])
            } else {
                setFullRPList((fullRPList) =>
                    fullRPList.map((item) => {
                        if (item.link === rp.link) {
                            return {
                                ...item,
                                status: isStatusError
                                    ? 'Pas à jour'
                                    : RPstatus === true
                                    ? 'À jour'
                                    : 'Pas à jour',
                            }
                        }
                        return item
                    })
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RPstatus, isRPStatusLoading])
    return {
        RPstatus,
        isRPStatusLoading,
        isStatusError,
    }
}
