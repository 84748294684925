import Step from '../../../Components/Step/Step'
import DesignCard from '../../../Components/DesignCard/DesignCard'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import SecondaryButton from '../../../Components/Buttons/SecondaryButton'
import RPInput from '../../../Components/RPInput/RPInput'
import Confirmation from '../../../Components/Confirmation/Confirmation'
import HelpItem from '../../../Components/HelpItem/HelpItem'
import Editor from '../../../Components/Editor/Editor'
import EditorLoader from '../../../Components/Editor/EditorLoader'
import CharacterInfoCard from '../../../Components/CharacterInfoCard/CharacterInfoCard'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'

import { useCharacters } from '../../../Hooks/useCharacters'
import { useQueryClient } from '@tanstack/react-query'

import arrowDown from '../../../Assets/Arrows/down.svg'
import arrowDownDark from '../../../Assets/Arrows/down-dark.svg'

import { designs } from '../../../Data/designs'

import { lightThemes } from '../../../Data/const'

import {
    updateDraft,
    getDraft,
    postRP,
    removeSpaces,
    formatRP,
} from '../../../utils/utils'

import { useAuth } from '../../../Context/auth'
import { useTheme } from '../../../Context/theme'
import { useModal } from '../../../Context/modal'

import { Link, useNavigate, useBlocker } from '@tanstack/react-router'
import { useState, useEffect, useCallback } from 'react'

import './Write.css'
import { useRPContent } from '../../../Context/rpContent'
import { useDraftSaving } from '../../../Context/draftSaving'
import FullScreen from '../../../Components/FullScreen/FullScreen'

export default function Write() {
    const { setIsDraftBeingSaved, setLastSavedDate } = useDraftSaving()
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [selectedDesign, setSelectedDesign] = useState('None')
    const [charName, setCharName] = useState('')
    const [charNameTwo, setCharNameTwo] = useState('')
    const [imageOne, setImageOne] = useState('')
    const [imageTwo, setImageTwo] = useState('')
    const [imageThree, setImageThree] = useState('')
    const [rpName, setRpName] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isDraftLoading, setIsDraftLoading] = useState(true)
    const [isDraftError, setIsDraftError] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [draftContent, setDraftContent] = useState()
    const [formIsDirty, setFormIsDirty] = useState(false)

    const { activeCharacters } = useCharacters()
    const queryClient = useQueryClient()

    const { user, userIsLoading } = useAuth()
    const { theme } = useTheme()
    const { RPContent, setRPContent, wordCount, characterCount } =
        useRPContent()

    const { isModalOpen, setIsModalOpen } = useModal()
    const navigate = useNavigate()

    const { proceed, reset, status } = useBlocker({
        shouldBlockFn: () => formIsDirty,
        withResolver: true,
        enableBeforeUnload: false,
    })

    const fetchDraft = async () => {
        setIsDraftError(false)
        setIsDraftLoading(true)
        try {
            const { data } = await getDraft()
            setRPContent(removeSpaces(data))
            setDraftContent(data)
            setIsDraftLoading(false)
            setIsDraftError(false)
        } catch {
            setIsDraftLoading(false)
            setIsDraftError(true)
            setRPContent('')
        }
    }

    const saveDraft = async () => {
        setIsDraftBeingSaved(true)
        try {
            await updateDraft(RPContent)
            setLastSavedDate(Date.now())
            setFormIsDirty(false)
        } catch {
            setIsDraftBeingSaved(false)
        } finally {
            setIsDraftBeingSaved(false)
        }
    }

    useEffect(() => {
        if (!userIsLoading && user) {
            setIsDraftLoading(true)
            fetchDraft()
            setIsLoading(true)
            setIsLoading(false)
        }
        if (!userIsLoading && !user) {
            navigate({
                to: '/accueil',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userIsLoading])

    useEffect(() => {
        const timer = setTimeout(() => {
            saveDraft()
        }, 30000)
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RPContent?.length])

    const alertUser = useCallback(
        (e) => {
            if (formIsDirty) {
                e.preventDefault()
                e.returnValue = ''
            }
        },
        [formIsDirty]
    )

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertUser])

    useEffect(() => {
        setFormIsDirty(
            Boolean(RPContent !== draftContent && RPContent !== '"<p></p>"')
        )
    }, [RPContent, draftContent])

    const handleCharacterChoice = (e) => {
        setSelectedCharacter(e.target.value)
    }

    const copyRP = () => {
        const { codeStart, codeEnd } = formatRP({
            selectedDesign,
            rpName,
            charName,
            charNameTwo,
            imageOne,
            imageTwo,
            imageThree,
        })
        navigator.clipboard.writeText(
            codeStart + removeSpaces(RPContent) + codeEnd
        )
        setIsCopied(true)
    }

    const validateRP = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const publishRP = async () => {
        setPostIsLoading(true)
        setFormIsDirty(false)
        try {
            await postRP(selectedCharacter, wordCount, characterCount)
            setIsModalOpen(false)
            navigate({
                to: `/confirmation?character=${selectedCharacter}&wordcount=${wordCount}`,
            })
        } catch (_) {
            setShowModalError(true)
        } finally {
            setPostIsLoading(false)
            setLastSavedDate('')
            queryClient.invalidateQueries({
                queryKey: ['check-rp', selectedCharacter],
            })
            queryClient.invalidateQueries({ queryKey: ['get-stats'] })
        }
    }

    const designNoImages = designs.filter(
        (item) => !item.hasImage1 && !item.hasImage2 && !item.hasImage3
    )
    const designOneImage = designs.filter(
        (item) => item.hasImage1 && !item.hasImage2 && !item.hasImage3
    )
    const designTwoImages = designs.filter(
        (item) => item.hasImage1 && item.hasImage2 && !item.hasImage3
    )
    const designThreeImages = designs.filter(
        (item) => item.hasImage1 && item.hasImage2 && item.hasImage3
    )

    return (
        <div>
            <Step
                help={true}
                name={'Choisir un personnage'}
                number={1}
                isCompleted={selectedCharacter}>
                <p>
                    Les statistiques de votre RP seront associées à ce
                    personnage. Pensez à bien choisir le personnage avec lequel
                    vous écrivez ce rp 🙂
                </p>
            </Step>
            <div className="sm:flex items-center mb-6">
                <div className="relative sm:mb-0 mb-4">
                    <select
                        className="py-4 pl-4 pr-12 w-80 bg-background rounded-md border border-solid border-border-color appearance-none"
                        onChange={handleCharacterChoice}
                        data-testid="character-select"
                        disabled={isLoading}>
                        <option value="">Nom du personnage</option>
                        {activeCharacters?.map((item, index) => (
                            <option key={`${item}-${index}`} value={item.name}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    <img
                        src={
                            lightThemes.includes(theme)
                                ? arrowDown
                                : arrowDownDark
                        }
                        alt="fleche bas"
                        className="absolute left-72 top-6"
                    />
                </div>
                <Link to="/personnages" className="sm:ml-8 text-secondary">
                    Gérer mes personnages
                </Link>
            </div>

            <Step
                name={'Rédiger le RP'}
                number={2}
                isCompleted={RPContent?.length > 9}></Step>
            {isDraftError && (
                <InfoMessage type="warning" infoStyle="mb-4">
                    <p>
                        Une erreur est survenue au moment de récupérer le
                        brouillon.
                    </p>
                    <p
                        className="font-semibold cursor-pointer"
                        onClick={fetchDraft}>
                        Ré-essayer
                    </p>
                </InfoMessage>
            )}
            {
                <div className="sm:flex">
                    <div className="flex flex-col sm:w-[70%] w-full">
                        {isDraftLoading ? (
                            <EditorLoader />
                        ) : (
                            <FullScreen>
                                <Editor
                                    saveDraft={saveDraft}
                                    isDraftLoading={isDraftLoading}
                                />
                            </FullScreen>
                        )}
                    </div>
                    {selectedCharacter ? (
                        <CharacterInfoCard
                            character={selectedCharacter}
                            characterList={activeCharacters}
                        />
                    ) : (
                        <div className="sm:w-[232px] w-full flex sm:flex-col bg-background sm:ml-4 rounded-md p-4 text-main h-fit text-sm text-color">
                            Choisissez un personnage pour voir ses informations
                            apparaître ici.
                        </div>
                    )}
                </div>
            }
            <Step
                help={true}
                name={'Choisir un design'}
                number={3}
                isCompleted={selectedDesign}>
                <p>
                    Si vous choisissez un design, il sera automatiquement
                    intégré à votre RP quand vous cliquerez sur "Copier le RP"
                    en bas de page. Vous pourrez ensuite coller votre RP
                    directement avec le design choisi 🙂
                </p>
                <p>
                    Vous pouvez également ne choisir aucun design. Dans ce cas,
                    seul le texte de votre RP sera copié.
                </p>
            </Step>
            <HelpItem title="Design sans images">
                <ul className="flex flex-wrap justify-center">
                    {designNoImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 1 image">
                <ul className="flex flex-wrap justify-center">
                    {designOneImage.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 2 images">
                <ul className="flex flex-wrap justify-center">
                    {designTwoImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 3 images">
                <ul className="flex flex-wrap justify-center">
                    {designThreeImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            {selectedDesign && (
                <div>
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasTitreRP && (
                        <RPInput
                            placeholder="Titre de mon RP"
                            func={setRpName}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasNomPerso && (
                        <RPInput
                            placeholder="Personnage à qui je réponds"
                            func={setCharName}
                            help={true}>
                            <p>
                                Ajoutez le pseudo du personnage à qui vous
                                répondez avec la même orthographe que sur votre
                                forum. Le design taggera automatiquement votre
                                partenaire de RP quand vous posterez votre
                                réponse 😉
                            </p>
                        </RPInput>
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasNomPerso2 && (
                        <RPInput
                            placeholder="Deuxième personnage à qui je réponds"
                            func={setCharNameTwo}
                            help={false}></RPInput>
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage1 && (
                        <RPInput
                            placeholder="URL de ma 1ère image"
                            func={setImageOne}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage2 && (
                        <RPInput
                            placeholder="URL de ma 2ème image"
                            func={setImageTwo}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage3 && (
                        <RPInput
                            placeholder="URL de ma 3ème image"
                            func={setImageThree}
                            help={false}
                        />
                    )}
                </div>
            )}
            <Step
                help={true}
                name={'Finaliser le RP'}
                number={4}
                isCompleted={isCopied}>
                <p>
                    Cliquez sur "Copier le RP" pour récupérer votre RP avec le
                    design choisi. Il sera prêt à être posté !
                </p>
                <p>
                    <strong>Attention</strong> : en cliquant sur "J'ai posté mon
                    RP", votre brouillon sera supprimé. Ne cliquez sur ce bouton
                    que quand vous aurez publié le RP !
                </p>
            </Step>
            <div className="flex flex-col sm:flex-row items-center justify-center my-4">
                <SecondaryButton clickFunc={copyRP}>
                    {isCopied ? 'RP copié' : 'Copier mon RP'}
                </SecondaryButton>
                <PrimaryButton clickFunc={validateRP}>
                    J'ai posté mon RP
                </PrimaryButton>
            </div>
            {isModalOpen && (
                <Confirmation
                    closeModal={closeModal}
                    publishRP={publishRP}
                    warning={
                        RPContent?.length &&
                        selectedCharacter &&
                        selectedDesign &&
                        isCopied
                    }
                    error={showModalError}
                    postIsLoading={postIsLoading}
                    source="write"
                />
            )}
            {status === 'blocked' && (
                <Confirmation
                    closeModal={reset}
                    source="warnBeforeLeaving"
                    warning
                    proceed={proceed}
                    reset={reset}
                />
            )}
        </div>
    )
}
