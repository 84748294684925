import { useState, useEffect } from 'react'

import MenuItem from '../../../Components/MenuItem/MenuItem'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import Spinner from '../../../Components/Spinner/Spinner'
import {
    mapAllTimeStats,
    mapTodayStats,
    mapThisMonthStats,
    mapThisYearStats,
} from '../../../utils/utils'
import { useStats } from '../../../Hooks/useStats'

import { useTheme } from '../../../Context/theme'

import classNames from 'classnames'
import { lightThemes, themeStatColors } from '../../../Data/const'

import arrowDown from '../../../Assets/Arrows/down.svg'
import arrownDownDark from '../../../Assets/Arrows/down-dark.svg'

import { START, THIS_MONTH, THIS_YEAR, TODAY } from '../../../Data/const'
import StatsList from '../../../Components/StatsList/StatsList'
import StatsGraph from '../../../Components/StatsGraph/StatsGraph'
import Filter from '../../../Components/Filter/Filter'

const defaultColors = [
    '#cfa5a1',
    '#cfbaa1',
    '#cacfa1',
    '#b0cfa1',
    '#a1cfc0',
    '#a1becf',
    '#a2a1cf',
    '#bfa1cf',
    '#cfa1cc',
    '#cfa1b5',
]

export default function Stats() {
    const [activeTab, setActiveTab] = useState('Liste')
    const [isDateFilterOpen, setIsDateFilterOpen] = useState(false)
    const [dateFilter, setDateFilter] = useState(START)
    const [isCharacterFilterOpen, setIsCharacterFilterOpen] = useState(false)
    const [characterFilter, setCharacterFilter] = useState([
        'Personnages actifs',
        'Personnages archivés',
    ])
    const [statsData, setStatsData] = useState({})

    const {
        activeCharacters,
        archivedCharacters,
        noCharacter,
        isStatsLoading,
        isStatsError,
    } = useStats()

    const { theme } = useTheme()
    const themeColors = themeStatColors[theme] || defaultColors

    const showEmptyState =
        !isStatsLoading &&
        activeCharacters?.length === 0 &&
        archivedCharacters?.length === 0 &&
        !isStatsError
    const showFilterWarning = characterFilter?.length === 0

    const openDateFilter = () => {
        setIsDateFilterOpen(!isDateFilterOpen)
    }

    const openCharacterFilter = () => {
        setIsCharacterFilterOpen(!isCharacterFilterOpen)
    }

    const handleDateFilter = (event) => {
        setDateFilter(event.target.value)
    }

    useEffect(() => {
        if (!isStatsLoading && !isStatsError) {
            let data
            switch (dateFilter) {
                case START:
                    data = mapAllTimeStats({
                        activeCharacters,
                        noCharacter,
                        archivedCharacters,
                        colors: themeColors,
                        characterFilter,
                    })
                    break
                case TODAY:
                    data = mapTodayStats({
                        activeCharacters,
                        noCharacter,
                        archivedCharacters,
                        colors: themeColors,
                        characterFilter,
                    })
                    break
                case THIS_MONTH:
                    data = mapThisMonthStats({
                        activeCharacters,
                        noCharacter,
                        archivedCharacters,
                        colors: themeColors,
                        characterFilter,
                    })
                    break
                case THIS_YEAR:
                    data = mapThisYearStats({
                        activeCharacters,
                        noCharacter,
                        archivedCharacters,
                        colors: themeColors,
                        characterFilter,
                    })
                    break
                default:
                    data = mapAllTimeStats({
                        activeCharacters,
                        noCharacter,
                        archivedCharacters,
                        colors: themeColors,
                        characterFilter,
                    })
            }
            setStatsData(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter, characterFilter, isStatsLoading, isStatsError])

    return (
        <>
            <h1 className="sm:ml-4">Mes Statistiques</h1>
            {isStatsError && (
                <InfoMessage type="warning" infoStyle="mb-4">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            )}
            <div className="sm:mt-0 mt-3  mb-3 sm:flex sm:justify-between">
                <div className="sm:flex">
                    <div className="date-filter-block mb-3 sm:mb-0">
                        <div
                            className="px-4 py-2 w-64 sm:mr-4 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                            onClick={openDateFilter}>
                            <p
                                className={
                                    dateFilter === START
                                        ? 'filter-group-inactive'
                                        : 'filter-group-active'
                                }>
                                {dateFilter}
                            </p>
                            <img
                                src={
                                    lightThemes.includes(theme)
                                        ? arrowDown
                                        : arrownDownDark
                                }
                                alt="flèche"
                                className={classNames(
                                    isDateFilterOpen ? 'rotate-180' : null,
                                    'w-4 h-4 ease-in-out duration-300'
                                )}
                            />
                        </div>
                        {isDateFilterOpen && (
                            <div className="p-4 w-64 mt-2 filter-group rounded-md border border-solid border-border-color">
                                <div className="flex items-center my-1">
                                    <input
                                        className="mr-3 w-5 h-5 status-filter"
                                        type="radio"
                                        value={START}
                                        checked={dateFilter === START}
                                        onChange={handleDateFilter}
                                    />
                                    <label htmlFor={START}>
                                        Depuis le début
                                    </label>
                                </div>
                                <div className="flex items-center my-1">
                                    <input
                                        className="mr-3 w-5 h-5 status-filter"
                                        type="radio"
                                        value={TODAY}
                                        checked={dateFilter === TODAY}
                                        onChange={handleDateFilter}
                                    />
                                    <label htmlFor={TODAY}>Aujourd'hui</label>
                                </div>
                                <div className="flex items-center my-1">
                                    <input
                                        className="mr-3 w-5 h-5 status-filter"
                                        type="radio"
                                        value={THIS_MONTH}
                                        checked={dateFilter === THIS_MONTH}
                                        onChange={handleDateFilter}
                                    />
                                    <label htmlFor={THIS_MONTH}>Ce mois</label>
                                </div>
                                <div className="flex items-center my-1">
                                    <input
                                        className="mr-3 w-5 h-5 status-filter"
                                        type="radio"
                                        value={THIS_YEAR}
                                        checked={dateFilter === THIS_YEAR}
                                        onChange={handleDateFilter}
                                    />
                                    <label htmlFor={THIS_YEAR}>
                                        Cette année
                                    </label>{' '}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="character-filter-block">
                        <div
                            className="px-4 py-2 w-64 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                            onClick={openCharacterFilter}>
                            <p
                                className={
                                    characterFilter.length === 2
                                        ? 'filter-group-inactive'
                                        : 'filter-group-active'
                                }>
                                {characterFilter.length === 2
                                    ? 'Tous les personnages'
                                    : 'Personnages filtrés'}
                            </p>
                            <img
                                src={
                                    lightThemes.includes(theme)
                                        ? arrowDown
                                        : arrownDownDark
                                }
                                alt="flèche"
                                className={classNames(
                                    isCharacterFilterOpen ? 'rotate-180' : null,
                                    'w-4 h-4 ease-in-out duration-300'
                                )}
                            />
                        </div>
                        {isCharacterFilterOpen && (
                            <div className="p-4 w-64 mt-2 filter-group rounded-md border border-solid border-border-color">
                                <Filter
                                    filters={characterFilter}
                                    setFilters={setCharacterFilter}
                                    value="Personnages actifs"
                                />
                                <Filter
                                    filters={characterFilter}
                                    setFilters={setCharacterFilter}
                                    value="Personnages archivés"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <section className="sm:mb-0 mb-4">
                    <ul className="flex mt-3 sm:mt-0 sm:justify-between">
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="stats-category"
                            name="Liste">
                            Liste
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="stats-category"
                            name="graph">
                            Graphique
                        </MenuItem>
                    </ul>
                </section>
            </div>
            <div className="flex sm:flex-row flex-col justify-between">
                <section className="w-full">
                    {isStatsLoading && <Spinner centered margin />}
                    {showEmptyState && (
                        <InfoMessage infoStyle="mb-4">
                            Vous n'avez pas encore ajouté de personnage actif ou
                            de RP écrit à votre compte. Une fois votre premier
                            RP écrit et lié à un personnage, vous verrez vos
                            statistiques de RP par personnage ici.
                        </InfoMessage>
                    )}
                    {!isStatsLoading &&
                        !showEmptyState &&
                        !showFilterWarning &&
                        (activeTab === 'Liste' ? (
                            <StatsList
                                activeCharacters={
                                    statsData?.statsList &&
                                    statsData?.statsList[0]
                                }
                                noCharacter={
                                    statsData?.noCharacterList &&
                                    statsData?.noCharacterList
                                }
                                archivedCharacters={
                                    statsData?.statsList &&
                                    statsData?.statsList[1]
                                }
                            />
                        ) : (
                            <StatsGraph
                                RPGraphList={statsData?.RPGraphList}
                                wordGraphList={statsData?.wordGraphList}
                                wordByRPGraphList={statsData?.wordByRPGraphList}
                                characterFilter={characterFilter}
                            />
                        ))}
                    {showFilterWarning && (
                        <InfoMessage infoStyle="mb-4">
                            Veuillez choisir au moins un type de personnage à
                            afficher.
                        </InfoMessage>
                    )}
                </section>
            </div>
        </>
    )
}
