import './CharacterInfoCard.css'
import Copy from '../../Assets/copy.svg'
import CopyDark from '../../Assets/copy-dark.svg'
import { isLightColor } from '../../utils/utils'

export default function CharacterInfoCard({ character, characterList }) {
    const defaultAvatar = 'https://i.imgur.com/hfXkZWE.png'

    const selectedCharacter = characterList
        .filter((char) => char.name === character)
        .shift()
    const copyColor = (e) => {
        navigator.clipboard.writeText(e.target.textContent)
    }
    return (
        <div className="sm:w-[232px] w-full flex sm:flex-col bg-background sm:ml-4 rounded-md p-4 text-main h-fit">
            <div className="character-info-card-image">
                <img
                    className="w-[200px] h-[320px] object-cover"
                    src={selectedCharacter?.picture || defaultAvatar}
                    alt="avatar personnage"
                />
                <div className="character-info-card-info">
                    <p>{selectedCharacter?.description}</p>
                </div>
            </div>
            <div className="ml-4 sm:ml-0">
                {selectedCharacter?.rpColor && (
                    <p
                        style={{
                            backgroundColor: selectedCharacter.rpColor,
                            color: isLightColor(selectedCharacter.rpColor)
                                ? '#333'
                                : '#fff',
                        }}
                        className="p-2 cursor-pointer flex items-center sm:mt-3 rounded-md"
                        onClick={(e) => copyColor(e)}>
                        <img
                            src={
                                isLightColor(selectedCharacter.rpColor)
                                    ? CopyDark
                                    : Copy
                            }
                            alt="copier"
                            className="mr-1"
                        />
                        {selectedCharacter.rpColor}
                    </p>
                )}
                {selectedCharacter?.forum && (
                    <p className="mt-3 py-1 px-2 text-sm rounded-md w-fit bg-main-background">
                        {selectedCharacter.forum}
                    </p>
                )}
                <h2 className="mb-1 mt-1">{selectedCharacter?.name}</h2>
                <div className="text-sm">
                    {selectedCharacter?.age && (
                        <p>{selectedCharacter.age} ans</p>
                    )}
                    <p>{selectedCharacter?.occupation}</p>
                </div>
                {selectedCharacter?.notes && (
                    <div className="w-full mt-2 text-sm font-light p-2 border border-dashed border-border-color">
                        {selectedCharacter.notes}
                    </div>
                )}
            </div>
        </div>
    )
}
