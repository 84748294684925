import './index.css'
import App from './App'

import ReactDOM from 'react-dom/client'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import {
    signupRoute,
    loginRoute,
    resetPasswordRoute,
    forgottenPasswordRoute,
} from './routes/authRoutes'
import {
    writeRoute,
    trackerRoute,
    charactersRoute,
    statsRoute,
    accountRoute,
    confirmationRoute,
} from './routes/navigationRoutes'
import { landingRoute, newsRoute, helpRoute } from './routes/staticRoutes'

import { rootRoute } from './routes/rootRoute'

const routeTree = rootRoute.addChildren([
    loginRoute,
    signupRoute,
    resetPasswordRoute,
    forgottenPasswordRoute,
    writeRoute,
    trackerRoute,
    charactersRoute,
    statsRoute,
    accountRoute,
    confirmationRoute,
    landingRoute,
    newsRoute,
    helpRoute,
])

const router = createRouter({ routeTree, scrollRestoration: true })
const rootElement = document.getElementById('root')
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <App>
            <RouterProvider router={router} />
        </App>
    )
}
