import { createContext, useContext, useState } from 'react'

const DraftSavingContext = createContext()

export function DraftSavingWrapper({ children }) {
    const [isDraftBeingSaved, setIsDraftBeingSaved] = useState(false)
    const [lastSavedDate, setLastSavedDate] = useState('')

    return (
        <DraftSavingContext.Provider
            value={{
                isDraftBeingSaved,
                setIsDraftBeingSaved,
                lastSavedDate,
                setLastSavedDate,
            }}>
            {children}
        </DraftSavingContext.Provider>
    )
}

export function useDraftSaving() {
    return useContext(DraftSavingContext)
}
