import close from '../../Assets/close.svg'
import closedark from '../../Assets/close-dark.png'
import PrimaryButton from '../Buttons/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton'
import Warning from '../Warning/Warning'
import InfoMessage from '../InfoMessage/InfoMessage'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'

import { Chart } from 'react-google-charts'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'
import AddRP from '../AddRP/AddRP'
import UpdateRP from '../UpdateRP/UpdateRP'

import classNames from 'classnames'
import AddCharacter from '../AddCharacter/AddCharacter'
import UpdateCharacter from '../UpdateCharacter/UpdateCharacter'

export default function Confirmation({
    closeModal,
    publishRP,
    warning,
    error,
    postIsLoading,
    source,
    characters,
    rpToUpdate,
    typeData,
    statusData,
    characterData,
    forumData,
    characterToUpdate,
    proceed,
    reset,
}) {
    const smallHeight = source === 'write' || source === 'warnBeforeLeaving'
    const cancelClick = () => {
        closeModal(false)
    }
    const { theme } = useTheme()

    const typeOptions = {
        pieHole: 0.4,
        chartArea: { left: '5%', top: '5%', width: '90%', height: '90%' },
        is3D: false,
        backgroundColor: 'transparent',
        colors: ['#a9c0eb', '#89a4d6', '#7090cc'],
        legend: 'none',
        fontName: 'Ubuntu',
    }
    const statusOptions = {
        pieHole: 0.4,
        chartArea: { left: '5%', top: '5%', width: '90%', height: '90%' },
        is3D: false,
        backgroundColor: 'transparent',
        colors: ['#C2E0C6', '#D8A7B1', '#DADADA'],
        legend: 'none',
        fontName: 'Ubuntu',
    }
    const characterOptions = {
        pieHole: 0.4,
        chartArea: { left: '5%', top: '5%', width: '90%', height: '90%' },
        is3D: false,
        backgroundColor: 'transparent',
        fontName: 'Ubuntu',
        colors: [
            '#cfa5a1',
            '#cfbaa1',
            '#cacfa1',
            '#b0cfa1',
            '#a1cfc0',
            '#a1becf',
            '#a2a1cf',
            '#bfa1cf',
            '#cfa1cc',
            '#cfa1b5',
        ],
        legend: 'none',
    }
    const forumOptions = {
        pieHole: 0.4,
        chartArea: { left: '5%', top: '5%', width: '90%', height: '90%' },
        is3D: false,
        backgroundColor: 'transparent',
        fontName: 'Ubuntu',
        colors: [
            '#cfa1b5',
            '#cfa1cc',
            '#bfa1cf',
            '#a2a1cf',
            '#a1becf',
            '#a1cfc0',
            '#b0cfa1',
            '#cacfa1',
            '#cfbaa1',
            '#cfa5a1',
        ],
        legend: 'none',
    }

    return (
        <div className="absolute top-0 left-0 h-full w-full bg-black/70">
            <div
                className={classNames(
                    smallHeight ? null : 'h-[90%]',
                    'bg-main-background text-main bg-h-fit sm:w-2/3 fixed top-8 left-0 right-0 m-auto rounded-md flex p-4 border border-border-color border-solid mx-2 sm:m-auto overflow-auto'
                )}>
                <img
                    src={lightThemes.includes(theme) ? close : closedark}
                    alt="fermer la fenêtre"
                    className="w-4 h-4 absolute top-4 right-4 cursor-pointer"
                    onClick={cancelClick}
                />
                {source === 'write' && (
                    <div className="text-center m-auto p-4">
                        <h3>Avez-vous bien posté votre RP ?</h3>
                        {!warning ? (
                            <Warning>
                                Attention, vous n'avez apparemment pas validé
                                les 4 étapes dans la rédaction de votre RP.
                                Pensez à vérifier que vous n'avez rien oublié :
                                personnage lié, texte du RP, design du RP et RP
                                copié puis posté.
                            </Warning>
                        ) : null}
                        <p>
                            En cliquant sur "J'ai posté mon RP", votre brouillon
                            sera supprimé. Ne cliquez sur ce bouton que quand
                            vous aurez publié le RP !
                        </p>
                        {error && (
                            <InfoMessage type="warning">
                                <p>
                                    Une erreur est survenue au moment de poster
                                    votre RP. Veuillez ré-essayer.
                                </p>
                            </InfoMessage>
                        )}
                        <div className="mt-6 flex sm:flex-row flex-col items-center justify-center">
                            <SecondaryButton clickFunc={cancelClick}>
                                Oups, pas encore
                            </SecondaryButton>
                            <PrimaryButton clickFunc={publishRP}>
                                {postIsLoading ? (
                                    <ButtonSpinner />
                                ) : (
                                    "Oui, c'est bon !"
                                )}
                            </PrimaryButton>
                        </div>
                    </div>
                )}
                {source === 'warnBeforeLeaving' && (
                    <div className="text-center m-auto p-4">
                        <h3>Souhaitez vous vraiment quitter cette page ?</h3>
                        {warning && (
                            <Warning>
                                Attention, votre brouillon n'a pas été
                                enregistré dans son intégralité. Si vous quittez
                                la page maintenant, vous perdrez une partie de
                                votre brouillon.
                            </Warning>
                        )}
                        <p>
                            Si vous le souhaitez, vous pouvez enregistrer votre
                            brouillon en cliquant sur l'icone "Sauvegarder" sous
                            l'éditeur de texte.
                        </p>
                        <div className="mt-6 flex sm:flex-row flex-col items-center justify-center">
                            <SecondaryButton clickFunc={reset}>
                                Revenir à l'éditeur
                            </SecondaryButton>
                            <PrimaryButton clickFunc={proceed}>
                                Quitter la page
                            </PrimaryButton>
                        </div>
                    </div>
                )}
                {source === 'add' && (
                    <AddRP characters={characters} closeModal={closeModal} />
                )}
                {source === 'update' && (
                    <UpdateRP
                        characters={characters}
                        closeModal={closeModal}
                        rpToUpdate={rpToUpdate}
                    />
                )}
                {source === 'addChar' && (
                    <AddCharacter closeModal={closeModal} />
                )}
                {source === 'updateChar' && (
                    <UpdateCharacter
                        characterToUpdate={characterToUpdate}
                        closeModal={closeModal}
                    />
                )}
                {source === 'stats' && (
                    <div className="sm:mt-4 h-fit w-full sm:w-[600px] sm:m-auto flex sm:flex-row sm:flex-wrap flex-col justify-center items-center">
                        <Chart
                            chartType="PieChart"
                            width="250px"
                            height="250px"
                            data={typeData}
                            options={typeOptions}
                        />
                        <Chart
                            chartType="PieChart"
                            width="250px"
                            height="250px"
                            data={statusData}
                            options={statusOptions}
                        />
                        <Chart
                            chartType="PieChart"
                            width="250px"
                            height="250px"
                            data={characterData}
                            options={characterOptions}
                        />
                        <Chart
                            chartType="PieChart"
                            width="250px"
                            height="250px"
                            data={forumData}
                            options={forumOptions}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
