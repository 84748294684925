import React, { useState } from 'react'
import { Link } from '@tanstack/react-router'

import logo from '../../../Assets/logo.svg'
import logoDark from '../../../Assets/logo-dark.svg'
import loader from '../../../Assets/loader.gif'

import HeaderLink from './HeaderLink/HeaderLink'

import { lightThemes } from '../../../Data/const'

import { useAuth } from '../../../Context/auth'
import { useTheme } from '../../../Context/theme'

import './Header.css'
import classNames from 'classnames'

export default function Header() {
    const { user, userIsLoading } = useAuth()
    const { theme } = useTheme()
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
    const openBurgerMenu = () => {
        setBurgerMenuOpen(!burgerMenuOpen)
    }
    return (
        <nav className="bg-background text-main sm:flex justify-between items-center w-full px-8 py-4">
            <div className="flex justify-between items-center">
                <Link to="/">
                    <img
                        className="w-28"
                        src={lightThemes.includes(theme) ? logo : logoDark}
                        alt="logo airpéger"
                    />
                </Link>
                <div
                    onClick={openBurgerMenu}
                    className="w-10 h-10 mt-2 cursor-pointer sm:hidden">
                    <div
                        className={
                            burgerMenuOpen
                                ? 'nav-burger-line nav-burger-one nav-burger-one-open'
                                : 'nav-burger-line nav-burger-one'
                        }></div>
                    <div
                        className={
                            burgerMenuOpen
                                ? 'nav-burger-line nav-burger-two nav-burger-two-open'
                                : 'nav-burger-line nav-burger-two'
                        }></div>
                    <div
                        className={
                            burgerMenuOpen
                                ? 'nav-burger-line nav-burger-three nav-burger-three-open'
                                : 'nav-burger-line nav-burger-three'
                        }></div>
                </div>
            </div>
            {userIsLoading ? (
                <div className="sm:flex hidden">
                    <img
                        className="w-16 h-16 p-1 border border-solid border-border-color rounded-full mr-4"
                        src={loader}
                        alt="avatar"
                    />
                </div>
            ) : user && user.picture ? (
                <>
                    <ul
                        className={classNames(
                            'flex sm:flex-row flex-col sm:my-0 mt-8',
                            burgerMenuOpen ? 'sm:flex' : 'sm:flex hidden'
                        )}>
                        {
                            <HeaderLink
                                burgerMenuOpen={burgerMenuOpen}
                                setBurgerMenuOpen={setBurgerMenuOpen}
                                name={'Écrire un RP'}
                                link={'/'}
                            />
                        }
                        {
                            <HeaderLink
                                burgerMenuOpen={burgerMenuOpen}
                                setBurgerMenuOpen={setBurgerMenuOpen}
                                name={'RPs'}
                                link={'/rps'}
                            />
                        }
                        {
                            <HeaderLink
                                burgerMenuOpen={burgerMenuOpen}
                                setBurgerMenuOpen={setBurgerMenuOpen}
                                name={'Personnages'}
                                link={'/personnages'}
                            />
                        }
                        {
                            <HeaderLink
                                burgerMenuOpen={burgerMenuOpen}
                                setBurgerMenuOpen={setBurgerMenuOpen}
                                name={'Statistiques'}
                                link={'/statistiques'}
                            />
                        }
                        {
                            <HeaderLink
                                burgerMenuOpen={burgerMenuOpen}
                                setBurgerMenuOpen={setBurgerMenuOpen}
                                name={'Compte'}
                                link={'/account'}
                                showOnMobile
                            />
                        }
                    </ul>
                    <div className="sm:mt-0 sm:ml-4 mt-4 sm:block hidden">
                        <Link to="/account">
                            <img
                                className="w-16 h-16 min-w-16 p-1 border border-solid border-border-color rounded-full mr-4"
                                src={user.picture}
                                alt="avatar"
                            />
                        </Link>
                    </div>
                </>
            ) : (
                <ul
                    className={classNames(
                        'flex sm:flex-row flex-col sm:my-0 mt-8',
                        burgerMenuOpen ? 'sm:flex' : 'sm:flex hidden'
                    )}>
                    {
                        <HeaderLink
                            burgerMenuOpen={burgerMenuOpen}
                            setBurgerMenuOpen={setBurgerMenuOpen}
                            name={'Se connecter'}
                            link={'/login'}
                        />
                    }
                    {
                        <HeaderLink
                            burgerMenuOpen={burgerMenuOpen}
                            setBurgerMenuOpen={setBurgerMenuOpen}
                            name={"S'inscrire"}
                            link={'/signup'}
                        />
                    }
                </ul>
            )}
        </nav>
    )
}
