import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Bold from '@tiptap/extension-bold'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'

import MenuBar from './MenuBar'
import Counter from './Counter'
import { useRPContent } from '../../Context/rpContent'

const extensions = [StarterKit, Bold, TextStyle, Color, Underline]

const Editor = ({ saveDraft }) => {
    const { RPContent } = useRPContent()
    return (
        <EditorProvider
            extensions={extensions}
            content={RPContent}
            slotBefore={<MenuBar />}
            editorContainerProps={{
                className:
                    'border border-solid border-border-color rounded-md p-6 mb-2 h-[420px]',
            }}>
            <Counter saveDraft={saveDraft} />
        </EditorProvider>
    )
}

export default Editor
