import PrimaryButton from '../Buttons/PrimaryButton'
import InfoMessage from '../InfoMessage/InfoMessage'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import Tooltip from '../Tooltip/Tooltip'
import { useState } from 'react'
import { updateCharacter } from '../../utils/utils'
import { useQueryClient, useMutation } from '@tanstack/react-query'

export default function UpdateCharacter({ closeModal, characterToUpdate }) {
    const [newCharacterAge, setCharacterAge] = useState(characterToUpdate?.age)
    const [newCharacterRpColor, setCharacterRpColor] = useState(
        characterToUpdate?.rpColor
    )
    const [newCharacterOccupation, setCharacterOccupation] = useState(
        characterToUpdate?.occupation
    )
    const [newCharacterDescription, setCharacterDescription] = useState(
        characterToUpdate?.description
    )
    const [newCharacterNotes, setCharacterNotes] = useState(
        characterToUpdate?.notes || ''
    )
    const [newCharacterForum, setCharacterForum] = useState(
        characterToUpdate?.forum || ''
    )
    const [newCharacterPicture, setCharacterPicture] = useState(
        characterToUpdate?.picture
    )
    const [newCharacterIsArchived, setIsArchived] = useState(
        characterToUpdate?.isArchived || false
    )

    const queryClient = useQueryClient()
    const {
        mutate: modifyCharacter,
        isError: isUpdateCharacterError,
        isLoading: isUpdateCharacterLoading,
    } = useMutation({
        mutationKey: ['update-character'],
        mutationFn: () =>
            updateCharacter({
                name: characterToUpdate?.name,
                description: newCharacterDescription,
                age: newCharacterAge,
                rpColor: newCharacterRpColor,
                picture: newCharacterPicture,
                occupation: newCharacterOccupation,
                forum: newCharacterForum,
                notes: newCharacterNotes,
                isArchived: newCharacterIsArchived,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-characters'] })
            closeModal()
        },
    })

    const handleIsArchived = () => {
        setIsArchived(!newCharacterIsArchived)
    }

    return (
        <div className="text-center mx-auto p-4 w-11/12">
            <div className="flex justify-center m-auto mt-6">
                <h3>Modifier un personnage</h3>
            </div>
            <div className="my-6">
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterName" className="text-sm">
                            Nom du personnage
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterName"
                            name="Nom du personnage"
                            placeholder="Nom du personnage"
                            className="px-8 py-4 mb-4 w-full mt-2 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary bg-disabled"
                            value={characterToUpdate?.name}
                            disabled
                        />
                    </div>
                    <div className="text-left sm:w-1/2 sm:ml-2">
                        <label htmlFor="characterAge" className="text-sm">
                            Âge du personnage
                        </label>
                        <br />
                        <input
                            type="number"
                            id="characterAge"
                            name="Âge du personnage"
                            placeholder="Âge du personnage"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) => setCharacterAge(e.target.value)}
                            value={newCharacterAge}
                        />
                    </div>
                </div>
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterRpColor" className="text-sm">
                            Couleur en RP
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterRpColor"
                            name="Couleur en RP"
                            placeholder="#006100"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setCharacterRpColor(e.target.value)
                            }
                            value={newCharacterRpColor}
                        />
                    </div>
                    <div className="text-left sm:w-1/2 sm:ml-2">
                        <label
                            htmlFor="characterOccupation"
                            className="text-sm">
                            Occupation / métier
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterOccupation"
                            name="Occupation"
                            placeholder="Occupation / métier"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setCharacterOccupation(e.target.value)
                            }
                            value={newCharacterOccupation}
                        />
                    </div>
                </div>
                <div className="text-left sm:w-full sm:flex">
                    <div className="sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterPicture" className="text-sm">
                            Avatar du personnage (200x320 ou 400x640)
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterPicture"
                            name="Avatar"
                            placeholder="https://i.imgur.com/Tv6WFzv.png"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setCharacterPicture(e.target.value)
                            }
                            value={newCharacterPicture}
                        />
                    </div>
                    <div className="sm:w-1/2 sm:ml-2">
                        <label htmlFor="characterForum" className="text-sm">
                            Forum du personnage
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterForum"
                            name="Forum"
                            placeholder="Nom du forum"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) => setCharacterForum(e.target.value)}
                            value={newCharacterForum}
                        />
                    </div>
                </div>
                <div className="text-left sm:w-full">
                    <label htmlFor="characterDescription" className="text-sm">
                        Description du personnage
                    </label>
                    <br />
                    <textarea
                        type="text"
                        id="characterDescription"
                        name="Description du personnage"
                        placeholder="Les infos importantes du personnage"
                        className="px-8 py-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary h-32"
                        onChange={(e) =>
                            setCharacterDescription(e.target.value)
                        }
                        value={newCharacterDescription}
                    />
                </div>
                <div className="text-left sm:w-full">
                    <label htmlFor="characterNotes" className="text-sm">
                        Notes spéciales
                    </label>
                    <br />
                    <textarea
                        type="text"
                        id="characterNotes"
                        name="Notes du personnage"
                        placeholder="Vos notes sur le personnage, par exemple des caractères spéciaux que vous utilisez et que vous souhaitez conserver pour les copier/coller facilement"
                        className="px-8 py-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary h-28"
                        onChange={(e) => setCharacterNotes(e.target.value)}
                        value={newCharacterNotes}
                    />
                </div>
                <div className="flex items-center my-3">
                    <input
                        className="mr-3 w-5 h-5 status-filter"
                        type="checkbox"
                        checked={newCharacterIsArchived}
                        onChange={handleIsArchived}
                    />
                    <label htmlFor="isArchived">
                        Ce personnage est archivé
                    </label>
                    <Tooltip
                        tooltipStyle="sm:mt-[3px] mt-0"
                        position={'top-[-20px] sm:left-[50px]'}>
                        En archivant un personnage, il disparaîtra de votre
                        liste de personnages actifs, et sera visible dans votre
                        liste de personnages archivés. Vous conserverez
                        également ses statistiques de RP.
                    </Tooltip>
                </div>
            </div>
            {isUpdateCharacterError && (
                <InfoMessage infoStyle="mb-4" type="warning">
                    <p>
                        Une erreur est survenue au moment de modifier votre
                        personnage. Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}

            <div className="flex justify-center pb-8">
                <PrimaryButton clickFunc={modifyCharacter}>
                    {isUpdateCharacterLoading ? (
                        <ButtonSpinner />
                    ) : (
                        'Modifier le personnage'
                    )}
                </PrimaryButton>
            </div>
        </div>
    )
}
