import { useEffect } from 'react'

import EditLight from '../../Assets/edit-light.svg'
import EditDark from '../../Assets/edit-dark.svg'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

export default function RPFinishedItem({
    rp,
    characters,
    removeRP,
    characterFilters,
    openUpdateModal,
    setRpFinishedCount,
    hasCharacterFilter,
}) {
    const { theme } = useTheme()

    const showRP = () => {
        if (
            (hasCharacterFilter &&
                characterFilters.length === characters.length) ||
            !hasCharacterFilter
        ) {
            return true
        } else if (
            hasCharacterFilter &&
            characterFilters.length !== characters.length
        ) {
            return characterFilters.includes(rp.character)
        }
    }

    const deleteRP = () => {
        removeRP(rp.link)
    }

    const openModal = () => {
        openUpdateModal(rp)
    }

    useEffect(() => {
        setRpFinishedCount((rpFinishedCount) => [
            ...rpFinishedCount,
            {
                name: rp.name,
                link: rp.link,
                status: 'closed',
                isClosed: true,
                character: rp.character,
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        showRP() && (
            <div className="sm:w-[49%] bg-background text-main flex flex-col sm:justify-between items-start sm:items-center rounded-md px-6 py-4 my-4">
                <div className="flex justify-between w-full">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center w-4/5">
                        <span className="w-80px text-sm text-center text-grey border border-solid border-grey py-1 bg-light-grey rounded-md">
                            Terminé
                        </span>
                        <p className="sm:mx-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color truncate w-32">
                            {rp.character || 'Aucun personnage'}
                        </p>
                    </div>
                    <div className="flex">
                        <img
                            className="cursor-pointer edit-icon w-6 h-6 mr-4"
                            onClick={openModal}
                            src={
                                lightThemes.includes(theme)
                                    ? EditLight
                                    : EditDark
                            }
                            alt="modifier"
                        />
                        <img
                            className="cursor-pointer trash-icon w-6 h-6"
                            onClick={deleteRP}
                            src={
                                lightThemes.includes(theme) ? Trash : TrashDark
                            }
                            alt="supprimer"
                        />
                    </div>
                </div>
                <div className="w-full flex sm:flex-row flex-col sm:items-center mt-2">
                    {rp?.forum && (
                        <p className="sm:mr-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded bg-main-background truncate w-32">
                            {rp.forum}
                        </p>
                    )}
                    <a
                        href={rp.link}
                        target="_blank"
                        rel="noreferrer"
                        className="sm:my-0 my-4 rp-item-link truncate text-sm">
                        {rp.name || rp.link}
                    </a>
                </div>
            </div>
        )
    )
}
