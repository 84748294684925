import React from 'react'
import { Link } from '@tanstack/react-router'

export default function HeaderLink({
    name,
    link,
    showOnMobile,
    setBurgerMenuOpen,
    burgerMenuOpen,
}) {
    const closeBurgerMenu = () => {
        burgerMenuOpen && setBurgerMenuOpen(false)
    }
    return (
        <li className={`sm:mx-3 my-4 sm:my-0 ${showOnMobile && 'sm:hidden'}`}>
            <Link
                onClick={closeBurgerMenu}
                className="px-4 py-3 mb-2 rounded-md"
                activeProps={{
                    className: 'bg-light-secondary',
                }}
                to={link}>
                {name}
            </Link>
        </li>
    )
}
