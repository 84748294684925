import { useQuery } from '@tanstack/react-query'
import { getRPs } from '../utils/utils'
import { countRPPerCharacter, countRPPerForum } from '../utils/utils'

export const useRPs = () => {
    const {
        isLoading,
        isFetching,
        isError: isRPsError,
        data,
    } = useQuery(['get-rps'], getRPs, {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    })
    const trackedRPs = data?.data?.trackedRPs
    const characterData = trackedRPs && countRPPerCharacter(trackedRPs)
    const forumData = trackedRPs && countRPPerForum(trackedRPs)
    const formattedTrackedRPs = trackedRPs?.map((item) => ({
        ...item,
        status: item.manualTrackingStatus,
    }))
    const allToOpenRPs = data?.data?.toOpenRPs
    const allOpenRPs =
        formattedTrackedRPs &&
        Array.from(formattedTrackedRPs).filter((item) => !item.isClosed)
    const allClosedRPs =
        formattedTrackedRPs &&
        Array.from(formattedTrackedRPs).filter((item) => item.isClosed)
    const countByManualStatus =
        formattedTrackedRPs &&
        Array.from(formattedTrackedRPs).filter(
            (item) => item.manualTracking && !item.isClosed
        )
    const isRPsLoading = isLoading || isFetching
    return {
        characterData,
        forumData,
        allToOpenRPs,
        allOpenRPs,
        allClosedRPs,
        countByManualStatus,
        isRPsLoading,
        isRPsError,
    }
}
